import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
// sections
import { LoginForm } from "../../sections/auth/login";

const StyledContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // alignItems: "center",
  padding: "40px",
  gap: "40px",
  width: "472px",
  height: "551px",
  background: "#FFFFFF",
  boxShadow: "0px 4px 20px rgba(170, 169, 184, 0.5)",
  borderRadius: "8px"
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Grid container sx={{ height: "100%" }}>
        <Grid item lg={6} sx={{ height: "100%", padding: "220px" }}>
          <img src="/assets/illustrations/admin-login.png" alt="login" />
        </Grid>
        <Grid item lg={6} sx={{ padding: "8%", backgroundColor: "#FE9A4D" }}>
          <StyledContent>
            <h2>Sign in</h2>
            <LoginForm />
          </StyledContent>
        </Grid>
      </Grid>
    </>
  );
}
