import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useContext, useEffect } from "react";
import { markedDeliveredReasons } from "src/constants/order_constants";
import { GlobalContext } from "src/context/GlobalState";
import DeliveredDifferentOrder from "../components/DeliveredDifferentOrder";
import { markOrderDeliveredValidator } from "src/validators/order.validation";
import { adminUpdateOrderStatus } from "src/api/orders";
import { statusCodes } from "src/utils/statusCodeUtils";

export default function DeliveryDialog({ orderId }) {
  const {
    dispatch,
    state: { orderState, alertState, globalState }
  } = useContext(GlobalContext);

  useEffect(() => {}, [orderState?.orderStatusUpdateRecord]);

  const handleSave = async () => {
    try {
      const validateObj = markOrderDeliveredValidator({ orderObj: orderState?.orderStatusUpdateRecord });
      if (validateObj?.error) {
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "Error", heading: "User Error", body: validateObj?.error, type: "error" } });
        dispatch({ type: "showMessage", payload: true });
      }
      dispatch({ type: "loading", payload: true });
      const response = await adminUpdateOrderStatus(validateObj);
      dispatch({ type: "loading", payload: false });
      if (response.httpStatusCode === 200) {
        dispatch({ type: "orderStatusUpdateRecord", payload: { ...orderState?.orderStatusUpdateRecord, orderId: null, statusId: null, reason: null, note: null, anotherOrderId: null } });
        dispatch({ type: "showMarkDeliveredDialog", payload: false });
        dispatch({ type: "reload", payload: !globalState.reload });
      } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "error" } });
        dispatch({ type: "showMessage", payload: true });
      } else {
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "Server Error", heading: response?.message, body: response?.displayMessage, type: "error" } });
        dispatch({ type: "showMessage", payload: true });
      }
    } catch (error) {
      alert(error?.message);
    }
  };

  return (
    <Dialog maxWidth="md" sx={{ "& .MuiDialog-paper": { width: "600px" } }} open={orderState.showMarkDeliveredDialog} onClose={() => dispatch({ type: "showMarkDeliveredDialog", payload: false })}>
      <Grid sx={{ backgroundColor: "#F2F2F2", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DialogTitle>Mark As Delivered</DialogTitle>
        <Close
          onClick={() => {
            dispatch({ type: "showMarkDeliveredDialog", payload: false });
            dispatch({ type: "orderStatusUpdateRecord", payload: { ...orderState?.orderStatusUpdateRecord, orderId: null, statusId: null, reason: null, note: null, anotherOrderId: null, anotherReason: null } });
          }}
          sx={{ mx: 1, cursor: "pointer" }}
        />
      </Grid>
      <DialogContent sx={{ p: 0 }}>
        <Grid sx={{ m: 2, border: 1, borderColor: "#B4B4B4", borderRadius: "8px", px: 2, py: 2, gap: 3, borderStyle: "solid" }}>
          <FormControl fullWidth>
            <InputLabel sx={{ ml: -1.5, my: 1, color: "#B4B4B4" }} id="reason" shrink>
              Reasons
            </InputLabel>
            <Select labelId="Reason" value={orderState?.orderStatusUpdateRecord?.reason} name="reason" label="Reasons" variant="standard" onChange={(e) => dispatch({ type: "orderStatusUpdateRecord", payload: { ...orderState?.orderStatusUpdateRecord, orderId, statusId: 6, reason: e.target.value } })}>
              <MenuItem value={markedDeliveredReasons[1]}>{markedDeliveredReasons[1]}</MenuItem>
              <MenuItem value={markedDeliveredReasons[2]}>{markedDeliveredReasons[2]}</MenuItem>
              <MenuItem value={markedDeliveredReasons[3]}>{markedDeliveredReasons[3]}</MenuItem>
            </Select>
          </FormControl>
          {orderState?.orderStatusUpdateRecord?.reason === markedDeliveredReasons[1] ? (
            <DeliveredDifferentOrder orderId={parseInt(orderId)} />
          ) : orderState?.orderStatusUpdateRecord?.reason === markedDeliveredReasons[2] ? (
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel sx={{ ml: -1.5, my: 1, color: "#B4B4B4" }} id="reason" shrink>
                Note
              </InputLabel>
              <TextField
                sx={{ marginTop: 1 }}
                fullWidth
                aria-label="Note"
                margin="normal"
                multiline
                rows={3}
                value={orderState?.orderStatusUpdateRecord?.note}
                variant="standard"
                onChange={(e) => {
                  dispatch({ type: "orderStatusUpdateRecord", payload: { ...orderState?.orderStatusUpdateRecord, note: e.target.value } });
                }}
              />
            </FormControl>
          ) : orderState?.orderStatusUpdateRecord?.reason === markedDeliveredReasons[3] ? (
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel sx={{ ml: -1.5, my: 1, color: "#B4B4B4" }} id="reason" shrink>
                Other Reasons
              </InputLabel>
              <TextField
                sx={{ marginTop: 1 }}
                fullWidth
                aria-label="Another Reasons"
                margin="normal"
                multiline
                rows={3}
                value={orderState?.orderStatusUpdateRecord?.anotherReason}
                variant="standard"
                onChange={(e) => {
                  dispatch({ type: "orderStatusUpdateRecord", payload: { ...orderState?.orderStatusUpdateRecord, anotherReason: e.target.value } });
                }}
              />
            </FormControl>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid fullWidth sx={{ display: "flex", width: "100%", gap: 2, justifyContent: "center" }}>
          <Button
            onClick={() => {
              dispatch({ type: "showMarkDeliveredDialog", payload: false });
              dispatch({ type: "orderStatusUpdateRecord", payload: { ...orderState?.orderStatusUpdateRecord, orderId: null, statusId: null, reason: null, note: null, anotherOrderId: null, anotherReason: null } });
            }}
            sx={{
              color: "#333",
              backgroundColor: "#FFF",
              border: "1px solid #333",
              borderRadius: 1,
              px: 4,
              py: 1,
              "&:hover": {
                backgroundColor: "#FFF"
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSave()}
            sx={{
              color: "#FFF",
              backgroundColor: "#FE7C19",
              borderRadius: 2,
              px: 4,
              py: 1,
              "&:hover": {
                backgroundColor: "#FE7C19"
              }
            }}
          >
            Submit
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
