import { isUserSeller } from "src/constants/user_constants";
import { convertMarketPrices, processShowBrandInfo } from "src/utils/utils";

export const productCreateOrUpdateValidator = ({ productObj, createProductRecord, createProductVariantRecord, updateProductRecord }) => {
  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));
  try {
    const reqBody = productObj;
    const primaryImage = productObj.productImageUrls.find(({ isPrimary }) => isPrimary === true);

    if (productObj.productImageUrls.length === 0) return { error: "Please upload product image" };
    if (!primaryImage) return { error: "Please select primary Image" };
    if (!productObj.variantId && !productObj.variantName) return { error: "Please select variant types" };
    if (!productObj.brandId && !productObj.brandName) return { error: "Please select brand" };
    if (!productObj.unitId && !productObj.unitName) return { error: "Please select unit name" };
    if (!productObj.vatId) return { error: "Please select VAT type" };
    if (parseInt(productObj.skuTypeId) === parseInt(process.env.REACT_APP_DP_SKU_TYPE) && !productObj.additionalDetails) return { error: "Please add additional details for service" };

    if (createProductRecord) {
      if (!productObj.productName) return { error: "Please enter product name" };
      if (!productObj.description) return { error: "Please enter product description" };
      if (!productObj.skuTypeId && !productObj.skuTypeName) return { error: "Please select SKU type" };
      // if (productObj.mainCategories.length === 0) return { error: 'Please select category' }
      delete reqBody.productId;
      delete reqBody.productVariantId;
    } else if (createProductVariantRecord) {
      delete reqBody.productVariantId;
      if (!productObj.productId) return { error: "Please select product" };
      reqBody.productVariantImageUrls = productObj.productImageUrls;
      delete reqBody.productImageUrls;
      delete reqBody.skuTypeId;
      delete reqBody.skuTypeName;
      delete reqBody.productName;
      delete reqBody.additionalDetails;
      delete reqBody.description;
      delete reqBody.badge;
    } else if (updateProductRecord) {
      if (!productObj.productName) return { error: "Please enter product name" };
      if (!productObj.description) return { error: "Please enter product description" };
      if (!productObj.skuTypeId && !productObj.skuTypeName) return { error: "Please select SKU type" };
      // if (productObj.mainCategories.length === 0) return { error: 'Please select category' }
      if (!productObj.productVariantId) return { error: "Please select product" };
      if (!productObj.productId) return { error: "Please select product" };
    } else {
      return { error: "Invalid parameters passed" };
    }
    reqBody.isDefaultProductVariant = createProductRecord ? true : isSeller ? false : reqBody.isDefaultProductVariant;
    reqBody.mainCategories = reqBody.mainCategories.map(({ group, ...categories } = {}) => categories);

    delete reqBody.tagBasedCategory;
    delete reqBody.availableQuantity;

    if (reqBody?.badge?.trim().length === 0) reqBody.badge = null;
    if (reqBody.filterStatuses) delete reqBody.filterStatuses;

    return reqBody;
  } catch (error) {
    return { error: error?.message };
  }
};

export const brandCreateOrUpdateValidator = ({ brandObj, createBrand, showBrandLabels }) => {
  try {
    const reqBody = brandObj;
    if (!brandObj.brandId && !brandObj.name) return { error: "Brand name is required" };
    if (!brandObj.brandId && !brandObj.imageUrl) return { error: "Brand image is required" };
    reqBody.showBrand = processShowBrandInfo(showBrandLabels);
    if (!reqBody.showBrand) reqBody.showBrand = 0;

    delete reqBody.isArchived;
    delete reqBody.isDefault;
    delete reqBody.thumbnailName;
    delete reqBody.numberOfCategories;
    delete reqBody.numberOfPhotos;
    delete reqBody.tagBasedCategory;
    delete reqBody.noOfItems;

    return reqBody;
  } catch (error) {
    return { error: error?.message };
  }
};

export const priceMatrixCreateOrUpdateValidator = ({ priceMatrixObj }) => {
  try {
    const reqBody = priceMatrixObj;
    if (!priceMatrixObj.productId) return { error: "Product id is required" };
    if (!priceMatrixObj.productVariantId) return { error: "Product variant id is required" };
    if (!priceMatrixObj.priceMatrixId) return { error: "Price Matrix id is required" };

    reqBody.marketPrices = convertMarketPrices(priceMatrixObj.marketPrices);
    const { percentage, imageUrl, productName, variantName, categoryName, unitName, skuTypeName, brandName, price, status, createdAt, ...newReqBody } = reqBody;
    return newReqBody;
  } catch (error) {
    return { error: error?.message };
  }
};
