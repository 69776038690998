import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { Button, Container, Divider, Grid, TextField, alpha, Switch, Typography, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styled from "@emotion/styled";
import { orange } from "@mui/material/colors";
import { JIPANGE } from "src/constants/constants";
import { fetchJipangeConfigurations, updateJipangeConfigurations } from "../../api/jipange";
import { jipangeMiniTitle, jipangeSubTitle, jipangeContainer, jipangeText, jipangeTextWidth, addVariantButton, addProductButton, jipangeButtonContainers } from "src/styles/styles";
import headerStyle from "src/styles/headerStyle";
import SystemLoader from "src/components/dialogs/Loader/Loader";
import { GlobalContext } from "src/context/GlobalState";
import { statusCodes } from "src/utils/statusCodeUtils";

// ----------------------------------------------------------------------

export default function JipangeConfigurationPage() {
  const {
    dispatch,
    state: { alertState }
  } = useContext(GlobalContext);

  const [jipangeCashBack, setJipangeCashBackObj] = useState({ id: 0, name: JIPANGE.JIPANGE_CASHBACK_VALUE, value: 0 });
  const [jipangeConsecutiveOrders, setJipangeConsecutiveOrders] = useState({ id: 0, name: JIPANGE.JIPANGE_CASHBACK_ORDER_THRESHOLD, value: [] });
  const [priceChangeJipangeThreshold, setPriceChangeJipangeThreshold] = useState({ id: 0, name: JIPANGE.JIPANGE_PRICE_CHANGE_THRESHOLD, value: 0 });
  const [jipangePricechangeNotification, setJipangePriceChangeNotification] = useState({ id: 0, name: JIPANGE.SEND_JIPANGE_PRICE_CHANGE_NOTIFICATION, value: 0 });
  const [reload, setReload] = useState(false);

  //Make get request - set useEffect here
  useEffect(() => {
    fetchJipangeConfigurationsData();
  }, [reload]);

  const fetchJipangeConfigurationsData = async () => {
    try {
      dispatch({ type: "loading", payload: true });
      const resp = await fetchJipangeConfigurations();
      if (resp?.httpStatusCode === 200) {
        setJipangeCashBackObj(resp?.result?.data.find((jipange) => jipange?.name === JIPANGE.JIPANGE_CASHBACK_VALUE));
        setJipangeConsecutiveOrders(resp?.result?.data.find((jipange) => jipange?.name === JIPANGE.JIPANGE_CASHBACK_ORDER_THRESHOLD));
        setPriceChangeJipangeThreshold(resp?.result?.data.find((jipange) => jipange?.name === JIPANGE.JIPANGE_PRICE_CHANGE_THRESHOLD));
        setJipangePriceChangeNotification(resp?.result?.data.find((jipange) => jipange?.name === JIPANGE.SEND_JIPANGE_PRICE_CHANGE_NOTIFICATION));
        dispatch({ type: "loading", payload: false });
      } else if (resp.httpStatusCode === statusCodes.unAuthorizedAccess) {
        dispatch({ type: "loading", payload: false });
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: resp?.message, body: resp?.displayMessage, type: "warning" } });
        dispatch({ type: "showMessage", payload: true });
      } else {
        dispatch({ type: "loading", payload: false });
        alert(resp?.displayMessage);
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };
  const handleSaveJipangeConfig = async () => {
    try {
      dispatch({ type: "loading", payload: true });
      jipangeCashBack.value = parseInt(jipangeCashBack.value, 10);
      jipangeConsecutiveOrders.value = jipangeConsecutiveOrders.value;
      priceChangeJipangeThreshold.value = parseInt(priceChangeJipangeThreshold.value, 10);
      jipangePricechangeNotification.value = parseInt(jipangePricechangeNotification.value, 10);
      const jipangeConfig = [jipangeCashBack, jipangeConsecutiveOrders, priceChangeJipangeThreshold, jipangePricechangeNotification];
      const resp = await updateJipangeConfigurations({ jipangeConfig });
      dispatch({ type: "loading", payload: false });
      if (resp?.httpStatusCode === 200) {
        alert(resp?.displayMessage);
        setReload(!reload);
      } else {
        alert(resp?.displayMessage);
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.messsage);
    }
  };

  //Handle radio button click
  const onRadioButtonClick = (type) => {
    setJipangeConsecutiveOrders((prevState) => {
      if (!Array.isArray(prevState.value)) return prevState;
      const updatedValues = prevState?.value?.map((item) => (item.type === type ? { ...item, status: 1 } : { ...item, status: 0 }));
      return { ...prevState, value: updatedValues };
    });
  };

  // Handke input change for jipange consecutive order threshold
  const handleInputChange = (type, newValue) => {
    setJipangeConsecutiveOrders((prevState) => {
      if (!Array.isArray(prevState.value)) return prevState;
      const updatedValues = prevState?.value?.map((item) => (item.type === type ? { ...item, value: newValue ? parseInt(newValue, 10) : "" } : item));
      return { ...prevState, value: updatedValues };
    });
  };

  const OrangeSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: orange[600],
      fontSize: 24,
      "&:hover": {
        backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity)
      }
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: orange[600]
    }
  }));

  return (
    <>
      <Helmet>
        <title> Jipange configuration | Tushop Pamoja Limited </title>
      </Helmet>
      <SystemLoader />

      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        {/* Header Container */}
        <Grid container spacing={1} sx={{ mt: -4 }} style={headerStyle.headerContainer}>
          <Grid item xs={4}>
            <Typography variant="h4" sx={{ py: 2 }}>
              Jipange
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid maxWidth="xxl" container spacing={2} sx={{ my: 1 }}>
          <Grid item xs={8}>
            <p style={jipangeSubTitle}>General Preferences</p>
            <p style={jipangeMiniTitle}>Setup the base preferences for customers who avail Jipange</p>
          </Grid>
          <Grid item xs={4} style={jipangeButtonContainers}>
            <Button sx={{ mr: 1 }} variant="outlined" style={addVariantButton}>
              Cancel
            </Button>
            <Button onClick={handleSaveJipangeConfig} style={addProductButton}>
              Save changes
            </Button>
          </Grid>
        </Grid>
        <Grid maxWidth="xxl" container flex-direction="column" spacing={2} style={jipangeContainer}>
          <Grid item sx={4} md={4} lg={3}>
            <p style={jipangeSubTitle}>Cashback</p>
          </Grid>
          <Grid item sx={8} md={8} lg={9}>
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                  <p style={jipangeText}>Set cashback percentage on orders</p>{" "}
                </Grid>
                <Grid item style={jipangeTextWidth}>
                  <TextField onChange={(e) => setJipangeCashBackObj({ ...jipangeCashBack, value: e.target.value })} name="noOfConsecutiveOrders" variant="standard" value={jipangeCashBack?.value ?? 0} />
                </Grid>
                <Grid item>
                  <p style={jipangeText}> %</p>{" "}
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ mr: 2, my: 2 }} />
            <Grid item xs={12} sx={{ my: 1 }}>
              <FormControl fullWidth>
                <RadioGroup defaultValue={jipangeConsecutiveOrders?.value?.find((item) => item.status === 1)?.type?.toString()} name="cashback" sx={{ width: "100%" }}>
                  {jipangeConsecutiveOrders?.value.map((item) => (
                    <FormControlLabel
                      key={item.type}
                      value={item.type.toString()}
                      control={<Radio checked={item.status === 1} onChange={() => onRadioButtonClick(item.type)} />}
                      label={
                        <Grid container direction="row" alignItems="center" px={2} spacing={2}>
                          <p style={{ margin: 0, whiteSpace: "nowrap", paddingTop: "1em", paddingRight: "1em" }}>{`Available cashback ${item.type === 1 ? "on every" : "from your"}`}</p>
                          <TextField
                            variant="standard"
                            value={item.value}
                            onChange={(e) => handleInputChange(item.type, e.target.value)}
                            disabled={item.status !== 1}
                            InputProps={{
                              style: {
                                width: "100px"
                              },
                              inputProps: {
                                style: {
                                  textAlign: "center"
                                }
                              }
                            }}
                          />
                          <p style={{ margin: 0, whiteSpace: "nowrap" }}>consecutive uninterrupted orders</p>
                        </Grid>
                      }
                      sx={{ width: "100%" }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid maxWidth="xxl" container flex-direction="column" spacing={2} style={jipangeContainer}>
          <Grid item sx={4} md={4} lg={3}>
            <p style={jipangeSubTitle}>Price Changes</p>
          </Grid>
          <Grid item sx={8} md={8} lg={9}>
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item>
                  <p style={jipangeText}>Notifications</p>{" "}
                </Grid>
                <Grid item sx={{ mr: 8 }}>
                  <OrangeSwitch name="jipangePricechangeNotification" checked={jipangePricechangeNotification?.value ?? 0 === 1} onClick={(e) => setJipangePriceChangeNotification({ ...jipangePricechangeNotification, value: e.target.checked ? 1 : 0 })} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                  <p style={jipangeText}>Send notifications on price changes greater than or equal to </p>{" "}
                </Grid>
                <Grid item style={jipangeTextWidth}>
                  <TextField onChange={(e) => setPriceChangeJipangeThreshold({ ...priceChangeJipangeThreshold, value: e.target.value })} name="cashback" variant="standard" value={priceChangeJipangeThreshold?.value ?? 0} />
                </Grid>
                <Grid item>
                  <p style={jipangeText}> % </p>{" "}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
