export const orderingPlatform = {
  web: 1,
  app: 2,
  sellerPortal: 3
};

export const markedDeliveredReasons = {
  1: "Delivered with a different order",
  2: "Ordered but picked up",
  3: "Other Reasons"
};
