export const notificationIntitialState = {
  allowNotification: false
};

export const notificationReducer = (state, action) => {
  switch (action.type) {
    case "allowNotification":
      return { ...state, allowNotification: action.payload };
    default:
      return state;
  }
};
