import { FormControl, Grid, TextField } from "@mui/material";
import { useContext } from "react";
import { isUserSeller } from "src/constants/user_constants";
// eslint-disable-next-line
import { GlobalContext } from "src/context/GlobalState";

const QuantityField = () => {
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  const createNewBox = {
    boxSizing: "border-box",
    width: "100%",
    borderWidth: "1px 1px 1px 1px",
    padding: "10px",
    borderStyle: "solid",
    borderColor: "#BFBFBF",
    borderRadius: "8px 8px 8px 8px",
    marginBottom: "1em"
  };

  return (
    <>
      <br />
      <div style={createNewBox}>
        <p>
          <b>Quantity</b>
        </p>
        <Grid container justifyContent="space-between" gap={1}>
          <Grid xs={5.8}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                disabled={isSeller}
                label="Allocated (units)"
                onChange={(e) => {
                  dispatch({ type: "productRecord", payload: { ...productState?.productRecord, allocatedQuantity: !e.target.value || e.target.value === "" ? null : parseInt(e.target.value) } });
                }}
                margin="normal"
                variant="standard"
                value={productState.productRecord.allocatedQuantity}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid xs={5.8}>
            <FormControl fullWidth>
              <TextField fullWidth label="Available (units)" variant="standard" disabled margin="normal" value={productState.productRecord.availableQuantity} InputLabelProps={{ shrink: true }} />
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <br />
    </>
  );
};

export default QuantityField;
