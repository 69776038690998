import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import AccountPopover from "./AccountPopover";
import AlertPopup from "src/components/alert/AlertPopup";
import { useCallback, useContext, useEffect } from "react";
import { GlobalContext } from "src/context/GlobalState";

// ----------------------------------------------------------------------

const NAV_WIDTH = 224;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 80;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`
  }
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func
};

export default function Header({ onOpenNav }) {
  const {
    dispatch,
    state: { alertState }
  } = useContext(GlobalContext);

  const clearAlert = useCallback(() => {
    dispatch({ type: "alertClear" });
  }, [dispatch]);

  useEffect(() => {
    if (alertState?.showMessage) {
      const timer = setTimeout(() => {
        clearAlert();
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [alertState.showMessage, clearAlert]);
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" }
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1
          }}
        >
          <AccountPopover />
        </Stack>
        {alertState.showMessage && (
          <AlertPopup
            dispatch={dispatch}
            alertDetail={alertState.alertDetail}
            sx={{
              position: "absolute",
              top: 3,
              right: 2
            }}
          />
        )}
      </StyledToolbar>
    </StyledRoot>
  );
}
