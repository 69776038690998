import { Helmet } from "react-helmet-async";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, CircularProgress, Collapse, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CSVDownload } from "react-csv";
import { debounce } from "lodash";

import CategoryDialog from "src/pages/category/dialog/CategoryDialog";
import { findNewSortedArrayChanged, reorderList } from "src/utils/utils";
import { fetchCategoryExportData, fetchCategoryList, reorderCategoryListing } from "src/api/products";
import headerStyle from "src/styles/headerStyle";
import { StyledTableCell2, addProductButton, addVariantButton, brandIcons, tableHeadText } from "src/styles/styles";
import { CATEGORY_STATUS, CATEGORY_TYPE, PRODUCT_VIEW, SWITCH_TYPE, colors } from "src/constants/constants";
import buttonStyle from "src/styles/buttonStyle";
import { useNavigate } from "react-router-dom";
import { ChevronRight, Close, Delete, Edit, ExpandMore, Search, Share } from "@mui/icons-material";
import { categoryHeadCells } from "src/utils/product";
import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import tableStyle from "src/styles/tableStyle";
import OrangeSwitch from "src/components/switch/OrangeSwitch";
import { GlobalContext } from "src/context/GlobalState";
import SystemLoader from "src/components/dialogs/Loader/Loader";
import { deleteCategoryRecord } from "src/api/products";
import { VerticalDragIndicator } from "src/pages/category/components/drag_handle/VerticalDragIndicator";
import { categoriesCSV } from "src/constants/csv";
import { generateAdminCategoryShareLink } from "src/api/category";
import ShareDialog from "src/components/dialogs/ShareDialog/ShareDialog";

export default function CategoryPage() {
  const navigate = useNavigate();
  const [exportt, setExport] = useState(false);
  const [exportTable, setExportTable] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const [categoryArrayCopy, setCategoryArrayCopy] = useState([]);
  const [subCategoryArrayCopy, setSubCategoryArrayCopy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState(PRODUCT_VIEW.categories);
  const [sortCategory, setSortCategory] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const {
    dispatch,
    state: { globalState, categoryState }
  } = useContext(GlobalContext);
  const [subcategoryChanges, setSubcategoryChanges] = useState([]);
  const [searchText, setSearchText] = useState(null);

  const [showShareDialog, setShowShareDialog] = useState(false);
  const [shareCategoryLinkUrl, setShareCategoryLinkUrl] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        updateTableData();
      } catch (error) {
        alert(error.message);
      }
    };
    fetchData();
  }, [globalState.reload, searchText]);

  //Handle category expand
  const handleExpandClick = (itemId) => {
    setExpandedItems((prev) => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  //Fetch categories
  const updateTableData = async () => {
    setLoading(true);
    let response = await fetchCategoryList(searchText);
    if (response.httpStatusCode === 200) {
      const formattedArray = response?.result?.data.map((category) => ({
        ...category,
        sortOrder: category.sortOrder.toString(),
        subCategories: category.subCategories
          .map((subCategory) => ({
            ...subCategory,
            sortOrder: subCategory.sortOrder.toString()
          }))
          .sort((a, b) => a.sortOrder - b.sortOrder)
      }));
      setCategoryArray(formattedArray);
      setCategoryArrayCopy(formattedArray);
      let subCategory = formattedArray.reduce((acc, item) => {
        let subCategories = item.subCategories.map((subCategory) => ({ categoryId: subCategory.categoryId, sortOrder: parseInt(subCategory.sortOrder) }));
        return acc.concat(subCategories);
      }, []);
      setSubCategoryArrayCopy(subCategory);
      setLoading(false);
    } else {
      setLoading(false);
      alert(response?.message);
      setCategoryArray([]);
    }
  };

  //Export category
  const handleCategoryExport = async () => {
    try {
      dispatch({ type: "loading", payload: true });
      const response = await fetchCategoryExportData();
      if (response.httpStatusCode === 200) {
        setExportTable(response.result.data);

        if (exportt) {
          setExport(false);

          setTimeout(() => {
            setExport(true);
          }, 500);
        } else {
          setExport(true);
        }
        dispatch({ type: "loading", payload: false });
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message);
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message ?? "Unable to export category");
    }
  };

  //TODO: Handle category drag
  const handleCategoryDrag = useCallback(
    (result) => {
      //Handle drop outside the list
      if (!result.destination) return;
      const reorderedItems = reorderList(categoryArray, result.source.index, result.destination.index);
      setCategoryArray(reorderedItems);
      setSortCategory(true);
    },
    [categoryArray]
  );

  //TODO: Handle subcategory drag tracking
  const handleSubCategoryDrag = useCallback(
    (result) => {
      //Handle drop outside the list
      if (!result.destination) return;
      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;
      const sourceCategoryId = parseInt(result.source.droppableId.split("-")[1]);
      const destinationCategoryId = parseInt(result.destination.droppableId.split("-")[1]);

      let movedSubcategory = null;

      const updatedCategories = categoryArray.map((category) => {
        if (category.categoryId === sourceCategoryId || category.categoryId === destinationCategoryId) {
          const updatedSubcategories = Array.from(category.subCategories);
          [movedSubcategory] = updatedSubcategories.splice(sourceIndex, 1);

          if (sourceCategoryId === destinationCategoryId) {
            updatedSubcategories.splice(destinationIndex, 0, movedSubcategory);
          } else {
            const destinationCategory = categoryArray.find((cat) => cat.categoryId === destinationCategoryId);
            destinationCategory.subCategories.splice(destinationIndex, 0, movedSubcategory);
          }

          // Update sortOrder based on new position
          updatedSubcategories.forEach((subCategory, index) => {
            subCategory.sortOrder = index + 1;
          });

          return { ...category, subCategories: updatedSubcategories };
        }
        return category;
      });

      // Reset sortOrder for each category's subcategories
      updatedCategories.forEach((category) => {
        let sortOrderCounter = 1;
        category.subCategories.forEach((subCategory) => {
          subCategory.sortOrder = sortOrderCounter;
          sortOrderCounter++;
        });
      });
      setCategoryArray(updatedCategories);

      const updatedSubcategoryArrays = updatedCategories.map((category) => {
        return category.subCategories.map((subcategory) => ({
          categoryId: subcategory.categoryId,
          sortOrder: subcategory.sortOrder.toString()
        }));
      });

      // Filter out empty arrays and return only arrays with changes
      const changedSubcategoryArrays = updatedSubcategoryArrays.filter((subcategoryArray) => subcategoryArray.length > 0);
      setSubcategoryChanges(changedSubcategoryArrays);
      setSortCategory(true);
    },
    [categoryArray]
  );

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,
    ...(isDragging && {
      background: "rgb(235,235,235)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }),
    borderBottom: "1px solid #ddd",
    padding: "8px"
  });

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  });

  //TODO: Handle sort
  const handleCategorySortOrder = async () => {
    try {
      dispatch({ type: "loading", payload: true });
      const categoriesToBeSorted = findNewSortedArrayChanged(categoryArrayCopy, categoryArray).map((cat) => {
        return { categoryId: cat.categoryId, sortOrder: parseInt(cat.sortOrder) };
      });
      const subCategoriesToBeSorted = subcategoryChanges.flat().map((subCategory) => ({ ...subCategory, sortOrder: parseInt(subCategory.sortOrder) }));
      // const subCategoriesChanged = findNewSortedArrayChanged(subCategoryArrayCopy,subCategoriesToBeSorted )
      const arraysToBeEdited = categoriesToBeSorted.concat(subCategoriesToBeSorted);
      const response = await reorderCategoryListing(arraysToBeEdited);
      setSortCategory(false);
      dispatch({ type: "loading", payload: false });
      if (response.httpStatusCode === 200) {
        alert(response?.message);
        dispatch({ type: "reload", payload: !globalState.reload });
      } else {
        alert(response?.message ?? "Error during creating/updating !");
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  // CSV report category
  const csvReport = {
    data: exportTable,
    headers: categoriesCSV.headers,
    filename: `Categories.csv`
  };

  const handleCategoryDelete = async () => {
    try {
      dispatch({ type: "loading", payload: true });
      let response = await deleteCategoryRecord(selectedCategory);
      dispatch({ type: "loading", payload: false });
      if (response.httpStatusCode === 200) {
        alert(response?.message);
        setSelectedCategory(null);
        dispatch({ type: "categoryClear" });
        dispatch({ type: "reload", payload: !globalState.reload });
      } else {
        alert(response?.message ?? "Unable to delete Category");
      }
      setOpenDeleteDialog(false);
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  const deleteCategoryDialog = () => {
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={openDeleteDialog}
        onClose={() => {
          setSelectedCategory(null);
          setOpenDeleteDialog(false);
        }}
      >
        <Grid sx={{ backgroundColor: "#F2F2F2", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <DialogTitle>Delete Category</DialogTitle>
          <Close
            onClick={() => {
              setSelectedCategory(null);
              setOpenDeleteDialog(false);
            }}
            sx={{ mx: 1, cursor: "pointer" }}
          />
        </Grid>
        <DialogContent>
          <Typography style={{ color: "#404040", fontFamily: "Lato", fontSize: "18px" }}>You are about to delete a category. Any product not part of other categories will be deactivated. Do you want continue?</Typography>
        </DialogContent>
        <DialogActions style={{ margin: "auto" }}>
          <Button
            variant="outlined"
            style={{ ...addVariantButton, height: "auto", width: "auto", padding: "8px 20px" }}
            onClick={() => {
              setSelectedCategory(null);
              setOpenDeleteDialog(false);
            }}
          >
            No
          </Button>
          <Button variant="outlined" style={addProductButton} onClick={() => handleCategoryDelete()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCategoryLinkShare = async (categoryId) => {
    try {
      dispatch({ type: "loading", payload: true });
      const response = await generateAdminCategoryShareLink({ categoryId });
      if (response.httpStatusCode === 200) {
        setShareCategoryLinkUrl(response?.result?.data?.deepLink);
        dispatch({ type: "loading", payload: false });
        setShowShareDialog(true);
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message);
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message ?? "An error occured during category link share");
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Products/Categores </title>
      </Helmet>
      <SystemLoader />
      {selectedCategory && deleteCategoryDialog()}
      {showShareDialog ? <ShareDialog openDialog={showShareDialog} setShowShareDialog={setShowShareDialog} shareLink={shareCategoryLinkUrl} copied={copied} setCopied={setCopied} shareType="Category" /> : null}
      {categoryState && <CategoryDialog />}
      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        {/* Header Container */}
        <Grid container alignItems="center" spacing={1} sx={{ mt: -4 }} style={headerStyle.headerContainer}>
          <Grid item xs={4}>
            <Typography variant="h4">Products </Typography>
          </Grid>
          <Grid item xs={8} style={headerStyle.searchContainer}>
            <TextField
              onChange={debounce((e) => {
                const searchValue = e.target.value;
                if (searchValue.length < 3) {
                  setSearchText(null);
                } else {
                  setLoading(true);
                  setSearchText(searchValue);
                  updateTableData(searchValue);
                }
              }, 300)}
              placeholder="Search Category"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                    <Search />
                  </InputAdornment>
                ),
                style: headerStyle.searchTextField,
                inputProps: {
                  style: headerStyle.placeHolderText
                }
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid sx={{ my: 2 }} maxWidth="xxl" spacing={2} gap={2} style={{ ...headerStyle.subHeaderContainer, justifyContent: "space-between" }}>
          <Grid gap={1} item xs={6} style={buttonStyle.buttonContainer}>
            <Button onClick={(e) => navigate("/dashboard/products", { replace: true })} style={{ ...buttonStyle.simpleButton, backgroundColor: currentView === PRODUCT_VIEW.categories ? "#D9D9D9" : "#2C2C2C", color: currentView === PRODUCT_VIEW.categories ? "#2C2C2C" : "##D9D9D9" }}>
              Catalogue
            </Button>
            <Button style={{ ...buttonStyle.simpleButton, backgroundColor: currentView === PRODUCT_VIEW.categories ? "#EF7B22" : null, color: currentView === PRODUCT_VIEW.categories ? "#FFF" : "#2C2C2C" }}>Categories</Button>
          </Grid>
          {sortCategory ? (
            <Grid gap={1} style={{ ...headerStyle.subHeaderContainer, justifyContent: "space-between" }}>
              <Grid item xs={4}>
                <Button
                  onClick={(e) => {
                    setCategoryArray(categoryArrayCopy);
                    setSortCategory(false);
                  }}
                  style={{ ...addVariantButton, border: "1px solid #333" }}
                >
                  Cancel{" "}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button onClick={(e) => handleCategorySortOrder()} style={addProductButton}>
                  Confirm{" "}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid gap={1} style={{ ...headerStyle.subHeaderContainer, justifyContent: "space-between" }}>
              <Grid item xs={4}>
                <Button
                  onClick={(e) => {
                    dispatch({ type: "categoryType", payload: CATEGORY_TYPE.primaryCategory });
                    dispatch({ type: "showCategoryDialog", payload: true });
                  }}
                  variant="outlined"
                  endIcon={<img alt="add" src="/assets/icons/button/add_icon.svg" />}
                  style={{ ...addProductButton, border: "none" }}
                >
                  Add Category
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button onClick={() => handleCategoryExport()} variant="outlined" style={addVariantButton} endIcon={<img alt="add" src="/assets/icons/button/arrow_down_black.svg" />}>
                  Export
                </Button>
                {exportt ? <CSVDownload {...csvReport} /> : <></>}
              </Grid>
            </Grid>
          )}
        </Grid>

        {/*Table Container */}
        <DragDropContext onDragEnd={handleCategoryDrag}>
          <Droppable droppableId="categories">
            {(provided, snapshot) => (
              <TableContainer component={Paper} {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                      <EnhancedTableHead headCells={categoryHeadCells} rowCount={categoryArray.length} />
                      <StyledTableCell2>{""}</StyledTableCell2>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <CircularProgress sx={{ color: colors.primary }} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableBody>
                      {categoryArray.map((category, index) => {
                        const isExpanded = expandedItems[category.categoryId];
                        return (
                          <Draggable key={category.categoryId} draggableId={`category-${category.categoryId}`} index={index} isDragDisabled={searchText ? true : false}>
                            {(provided, snapshot) => (
                              <>
                                <TableRow ContainerComponent="li" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                  <TableCell align="left" style={{ ...tableStyle.cellStyle, position: "relative" }}>
                                    <Grid alignItems="center" style={{ display: "flex" }}>
                                      {!searchText && <VerticalDragIndicator />}
                                      {Array.isArray(category.subCategories) && category.subCategories.length > 0 && (
                                        <IconButton sx={{ my: 1 }} onClick={() => handleExpandClick(category.categoryId)}>
                                          {isExpanded ? <ExpandMore /> : <ChevronRight />}
                                        </IconButton>
                                      )}
                                      <IconButton sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                                        <img style={brandIcons} alt="add" src={category.imageUrl} />
                                      </IconButton>
                                      <ListItemText primary={category.name} />
                                    </Grid>
                                  </TableCell>
                                  <TableCell align="right">
                                    <OrangeSwitch checked={category.status === CATEGORY_STATUS.active} switchType={SWITCH_TYPE.categorySwitch} bodyObj={category} />
                                    <IconButton
                                      onClick={() => {
                                        dispatch({ type: "categoryRecord", payload: { ...categoryState.categoryRecord, parentId: category.categoryId } });
                                        dispatch({ type: "selectedParentRecord", payload: { ...categoryState.selectedParentRecord, name: category?.name, imageUrl: category?.imageUrl } });
                                        dispatch({ type: "categoryType", payload: CATEGORY_TYPE.subCategory });
                                        dispatch({ type: "showCategoryDialog", payload: true });
                                      }}
                                    >
                                      <img alt="add" src="/assets/icons/ic_variant.svg" />
                                    </IconButton>
                                    <IconButton>
                                      <Edit
                                        onClick={(e) => {
                                          dispatch({ type: "categoryRecord", payload: { ...categoryState.categoryRecord, categoryId: category?.categoryId, name: category?.name, imageUrl: category?.imageUrl, status: category?.status ?? CATEGORY_STATUS.inActive } });
                                          dispatch({ type: "categoryType", payload: CATEGORY_TYPE.primaryCategory });
                                          dispatch({ type: "selectedCategoryName", payload: category?.name });
                                          dispatch({ type: "showCategoryDialog", payload: true });
                                        }}
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <Share onClick={() => handleCategoryLinkShare(category?.categoryId)} />
                                    </IconButton>
                                    <IconButton>
                                      <Delete
                                        onClick={() => {
                                          setSelectedCategory(category.categoryId);
                                          setOpenDeleteDialog(true);
                                        }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                      <DragDropContext onDragEnd={handleSubCategoryDrag}>
                                        <Droppable droppableId={`subcategories-${category.categoryId}`} type="subCategory">
                                          {(subProvided, subSnapshot) => (
                                            <Table size="small">
                                              <TableBody ref={subProvided.innerRef} {...subProvided.droppableProps} style={getListStyle(subSnapshot.isDraggingOver)}>
                                                {category.subCategories.map((subCategory, subIndex) => (
                                                  <Draggable key={subCategory.categoryId} draggableId={`subCategory-${subCategory.categoryId}`} index={subIndex} isDragDisabled={searchText ? true : false}>
                                                    {(subProvided, subSnapshot) => (
                                                      <TableRow ref={subProvided.innerRef} {...subProvided.draggableProps} {...subProvided.dragHandleProps} style={getItemStyle(subSnapshot.isDragging, subProvided.draggableProps.style)}>
                                                        <TableCell style={{ width: "100%" }}>
                                                          <div style={{ display: "flex", alignItems: "center" }}>
                                                            {!searchText && <VerticalDragIndicator />}
                                                            <IconButton sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                                                              <img style={{ ...brandIcons, width: "25px", height: "25px" }} alt="add" src={subCategory.imageUrl} />
                                                            </IconButton>
                                                            <ListItemText primary={subCategory.name} />
                                                          </div>
                                                        </TableCell>
                                                        <TableCell align="right" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                          <IconButton>
                                                            <Delete
                                                              onClick={() => {
                                                                setSelectedCategory(subCategory.categoryId);
                                                                setOpenDeleteDialog(true);
                                                              }}
                                                            />
                                                          </IconButton>
                                                          <IconButton>
                                                            <Share onClick={() => handleCategoryLinkShare(subCategory?.categoryId)} />
                                                          </IconButton>
                                                          <IconButton>
                                                            <Edit
                                                              onClick={(e) => {
                                                                dispatch({ type: "categoryRecord", payload: { ...categoryState.categoryRecord, categoryId: subCategory?.categoryId, name: subCategory?.name, imageUrl: subCategory?.imageUrl, parentId: subCategory.parentId, status: category?.status ?? CATEGORY_STATUS.inActive } });
                                                                dispatch({ type: "categoryType", payload: CATEGORY_TYPE.subCategory });
                                                                dispatch({ type: "selectedCategoryName", payload: subCategory?.name });
                                                                dispatch({ type: "showCategoryDialog", payload: true });
                                                              }}
                                                            />
                                                          </IconButton>
                                                          <OrangeSwitch checked={subCategory.status === CATEGORY_STATUS.active} switchType={SWITCH_TYPE.subCategorySwitch} bodyObj={subCategory} />
                                                        </TableCell>
                                                      </TableRow>
                                                    )}
                                                  </Draggable>
                                                ))}
                                                {subProvided.placeholder}
                                              </TableBody>
                                            </Table>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </>
  );
}
