// component
import { isUserSeller } from "src/constants/user_constants";
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const user = JSON.parse(localStorage.getItem("user"));

const isSeller = isUserSeller(user);
export const navConfig = [
  {
    title: "products",
    path: "/dashboard/products",
    icon: icon("ic_products"),
    show: true
  },
  {
    title: "brands",
    path: "/dashboard/brands",
    icon: icon("ic_brands"),
    show: true
  },
  {
    title: "Orders",
    path: "/dashboard/orders",
    icon: icon("ic_orders"),
    show: true
  },
  {
    title: "Pricing Matrix",
    path: "/dashboard/priceMatrix",
    icon: icon("ic_priceMatrix"),
    show: true
  },
  {
    title: "CRM",
    path: "/dashboard/crm",
    icon: icon("ic_crm"),
    show: true
  },
  {
    title: "Promo Codes",
    path: "/dashboard/promos",
    icon: icon("ic_okoa"),
    show: true
  }
  // {
  //   title: 'Demand Outlook',
  //   path: '/dashboard/CRM',
  //   icon: icon('ic_demandOutlook'),
  // },
  // {
  //   title: 'Finance',
  //   path: '/dashboard/CRM',
  //   icon: icon('ic_finance'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export const chanelAdmnistrationNav = [
  {
    title: "Pamoja",
    path: "/dashboard/okoa/pamoja",
    icon: icon("ic_pamoja"),
    show: true
  },
  {
    title: "Jipange",
    path: "/dashboard/okoa/jipange",
    icon: icon("ic_jipange"),
    show: true
  }
];

export const configAdmnistrationNav = [
  {
    title: "Audit",
    path: "/dashboard/audit/logs",
    icon: icon("ic_notebook"),
    show: true
  }
];
// export default navConfig;
