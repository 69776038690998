import { updateFcmToken } from "src/api/users";
import { getToken, messaging } from "../firebase/config";

export const requestPermissionNotification = async (setTokenFound, setNotificationPermission) => {
  try {
    const permission = await Notification.requestPermission();
    setNotificationPermission(permission);
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID
      });

      // call api to update user token
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        await updateFcmToken({ userId: user.userId, fcmToken: token });
        setTokenFound(true);
        localStorage.setItem("notificationEnable", JSON.stringify(true));
      }
    } else if (permission === "denied") {
      console.log("Notification permission denied");
      localStorage.setItem("notificationEnable", JSON.stringify(false));
    } else {
      console.log("Unable to get notification permission");
      localStorage.setItem("notificationEnable", JSON.stringify(false));
    }
  } catch (error) {
    console.log("Notification permission request error", error?.message);
  }
};
