import { useContext } from "react";
// eslint-disable-next-line
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";
import FirebaseApp from "src/firebase/firebase";
import { brandCoverPhotoContainer } from "src/styles/styles";

const BrandCoverUpload = () => {
  const storage = getStorage(FirebaseApp);
  const {
    dispatch,
    state: { brandState }
  } = useContext(GlobalContext);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((data) => {
        handleUploadCoverImage(data);
      });
    }
  });

  const nullOrUndefined = (data) => {
    if (data === null || data === undefined) {
      return true;
    } else {
      return false;
    }
  };

  const handleUploadCoverImage = (image) => {
    const defaultFileSize = process.env.REACT_APP_FILESIZE_LIMIT;

    if (image?.size > defaultFileSize) {
      alert("Please upload product image less than 150kb?");
    } else {
      const Reference = ref(storage, `SKU/Brands/CoverPhotos/${Date.now()}-${image.name}`);
      uploadBytes(Reference, image).then((snapshot) => {
        console.log(snapshot);
        getDownloadURL(Reference).then((url) => {
          dispatch({ type: "selectedRecord", payload: { ...brandState.selectedRecord, coverPhoto: url } });
        });
      });
    }
  };

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  return (
    <div
      {...getRootProps()}
      style={{
        // padding: "20px",
        boxSizing: "border-box",
        marginTop: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        height: "420px",
        background: nullOrUndefined(brandState.selectedRecord.coverPhoto) ? "#F2F2F2" : "#232F3E",
        borderWidth: "1px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#BFBFBF",
        borderRadius: "8px 8px 8px 8px",
        alignItems: "center"
      }}
    >
      <input {...getInputProps()} />
      <img src={brandState.selectedRecord.coverPhoto ?? "/assets/NoCoverPhoto.png"} style={brandState.selectedRecord.coverPhoto === null ? { width: "159px", background: "F2F2F2", height: "102px", display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "15%" } : brandCoverPhotoContainer} alt="Product" />
      {!isSeller && <p style={{ color: nullOrUndefined(brandState.selectedRecord.coverPhoto) ? "black" : "white", textAlign: "center", fontWeight: "700", marginTop: "0.7em" }}> {nullOrUndefined(brandState.selectedRecord.coverPhoto) ? "click here or drop to add image" : "Change Cover Photo"}</p>}
    </div>
  );
};

export default BrandCoverUpload;
