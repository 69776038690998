// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { useEffect, useState } from "react";
import { StyledChart } from "./components/chart";
import Notifications from "./components/notification/Notification";
import ScrollToTop from "./components/scroll-to-top";
import { requestPermissionNotification } from "./utils/firebaseUtil";

// ----------------------------------------------------------------------

export default function App() {
  //Request notification permission
  const [isTokenFound, setTokenFound] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(null);

  useEffect(() => {
    if (!isTokenFound) requestPermissionNotification(setTokenFound, setNotificationPermission);
  }, [isTokenFound]);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      {isTokenFound && <Notifications />}
      <Router />
    </ThemeProvider>
  );
}
