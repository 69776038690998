import { Helmet } from "react-helmet-async";
import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, Container, Divider, Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import headerStyle from "src/styles/headerStyle";
import { useQuery } from "src/utils/utils";
import { useSearchParams } from "react-router-dom";
import { Cancel, CheckCircle, Search } from "@mui/icons-material";
import { debounce } from "lodash";
import { fetchAuditLogs } from "src/api/audit";
import { GlobalContext } from "src/context/GlobalState";
import { pTable } from "src/styles/styles";
import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import { getComparator, stableSort } from "src/utils/sortTables";
import { colors } from "src/constants/constants";
import { fDate } from "src/utils/formatTime";
import { statusCodes } from "src/utils/statusCodeUtils";

const AuditPage = () => {
  const searchQuery = useQuery().get("search") || null;

  const [tableArray, setTableArray] = useState([]);
  const [searchText, setSearchText] = useState(searchQuery);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalLogsCount, setTotalLogsCount] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("category");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const {
    dispatch,
    state: { alertState }
  } = useContext(GlobalContext);

  useEffect(() => {
    if (searchText && searchText.trim().length >= 3 && searchText !== "null") {
      setSearchParams({ search: searchText });
    } else {
      setSearchText(null);
      setSearchParams({});
    }
  }, [searchText, setSearchParams]);

  useEffect(() => {
    try {
      if (searchText && searchText.trim().length > 0 && searchText.trim().length < 3) return;
      updateTableData(searchText, pageSize, page);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
    // eslint-disable-next-line
  }, [searchText]);

  const updateTableData = async (searchText, pageSize, page) => {
    dispatch({ type: "clear" });
    setLoading(true);
    const response = await fetchAuditLogs({ searchText, pageSize, pageNumber: page });
    if (response.httpStatusCode === 200) {
      const { pageNumber, pageSize, totalCount } = response.result.data;
      const array = [];
      response.result.data.result.forEach((data) => {
        const { adminLogId, adminUser, actionType, status, actionDescription, actionTime, actionItem } = data;
        const body = {
          adminLogId,
          userName: `${adminUser?.firstName ?? ""} ${adminUser?.lastName ?? ""}`,
          email: adminUser?.email ?? "",
          actionType,
          status,
          actionTime,
          actionItem,
          actionDescription
        };
        array.push(body);
      });

      setTableArray(array);
      setPage(parseInt(pageNumber));
      setPageSize(parseInt(pageSize));
      setTotalLogsCount(totalCount);

      setSearchParams({ ...searchParams, search: searchText });

      setLoading(false);
    } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
      setLoading(false);
      dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
      dispatch({ type: "showMessage", payload: true });
    } else {
      setLoading(false);
      alert(response.message);
    }
  };

  const handleChangeSearch = (e) => {
    if (!e.target.value.trim()) {
      setSearchText(null);
    } else if (e.target.value < 3) {
      setSearchText(e.target.value);
    } else {
      setSearchParams({ search: e.target.value });
      setSearchText(e.target.value);
    }
  };

  const headCells = [
    {
      id: "userName",
      numeric: false,
      disablePadding: false,
      label: "User"
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email"
    },
    {
      id: "actionItem",
      numeric: false,
      disablePadding: false,
      label: "Item"
    },
    {
      id: "actionType",
      numeric: false,
      disablePadding: false,
      label: "Action"
    },
    {
      id: "actionDescription",
      numeric: false,
      disablePadding: false,
      label: "Description"
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status"
    },
    {
      id: "actionTime",
      numeric: false,
      disablePadding: false,
      label: "Tinestamp"
    }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <>
      <Helmet>
        <title> Dashboard: Audit </title>
      </Helmet>
      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        {/* Header Container */}
        <Grid container spacing={1} sx={{ mt: -4 }} style={headerStyle.headerContainer}>
          <Grid item xs={4}>
            <Typography variant="h4" sx={{ py: 2 }}>
              Audit
            </Typography>
          </Grid>
          <Grid item xs={8} style={headerStyle.searchContainer}>
            <TextField
              onChange={debounce((e) => {
                handleChangeSearch(e);
              }, 100)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  updateTableData(searchText, 10, 0);
                }
              }}
              placeholder="Search Log"
              defaultValue={searchText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                    <Search />
                  </InputAdornment>
                ),
                style: headerStyle.searchTextField,
                inputProps: {
                  style: headerStyle.placeHolderText
                }
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid maxWidth="xxl">
          <>
            <TableContainer>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                    <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={tableArray.length} style={{ whiteSpace: "nowrap" }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <CircularProgress sx={{ color: colors.primary }} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    stableSort(tableArray, getComparator(order, orderBy)).map((record) => (
                      <TableRow key={record.adminLogId}>
                        {/* eslint-disable-next-line */}
                        <TableCell align="left">
                          <p style={pTable}>{record.userName}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{record.email}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{record.actionItem}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{record.actionType}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{record.actionDescription}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{record.status ? <CheckCircle fontSize="medium" sx={{ color: "#2FAA73" }} /> : <Cancel sx={{ color: "#EC1C24" }} fontSize="medium" />}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{fDate(record.actionTime)}</p>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ height: "20px" }}>
              <TablePagination
                rowsPerPageOptions={[2, 10, 15, 20, 25]}
                component="div"
                count={totalLogsCount}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, newVal) => {
                  updateTableData(searchText, pageSize, newVal);
                }}
                onRowsPerPageChange={(e) => {
                  updateTableData(searchText, e.target.value, page);
                }}
              />
            </div>
          </>
        </Grid>
      </Container>
    </>
  );
};

export default AuditPage;
