import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import * as React from "react";

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = "AIzaSyCbg1rgEAWTMdfNX4D-lAVhdo1j5dVvs8c";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

export default function GoogleMaps({ onPlaceSelect }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const [loading, setLoading] = React.useState(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`, document.querySelector("head"), "google-maps");
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        setLoading(true);
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(
            {
              ...request,
              componentRestrictions: { country: "KE" } // Restrict to Kenya
            },
            (results) => {
              setLoading(false);
              callback(results);
            }
          );
        }
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(document.createElement("div")); // Create a new PlacesService instance
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handlePlaceSelect = (placeId) => {
    if (placesService.current) {
      placesService.current.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
          const details = {
            lat: place.geometry?.location?.lat(),
            lng: place.geometry?.location?.lng(),
            street: place.address_components.find((comp) => comp.types.includes("route"))?.long_name,
            locality: place.address_components.find((comp) => comp.types.includes("locality"))?.long_name,
            administrative_area: place.address_components.find((comp) => comp.types.includes("administrative_area_level_1"))?.long_name,
            postal_code: place.address_components.find((comp) => comp.types.includes("postal_code"))?.long_name,
            city: place.address_components.find((comp) => comp.types.includes("locality"))?.long_name,
            state: place.address_components.find((comp) => comp.types.includes("administrative_area_level_1"))?.long_name,
            country: place.address_components.find((comp) => comp.types.includes("country"))?.long_name,
            pincode: place.address_components.find((comp) => comp.types.includes("postal_code"))?.long_name ?? "",
            googlePin: place.geometry?.location?.lat() + "," + place.geometry?.location?.lng() ?? "",
            houseNumber: inputValue
          };

          console.log(details);
          // Pass the details to the parent component using the callback
          onPlaceSelect(details);
        }
      });
    }
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue && newValue.place_id) {
          handlePlaceSelect(newValue.place_id);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: <InputAdornment position="start">{loading ? <CircularProgress size={20} /> : <SearchIcon />}</InputAdornment>
          }}
        />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );
        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box key={index} component="span" sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
