import { FormControl, TextField } from "@mui/material";
import { useContext } from "react";
import { isUserSeller } from "src/constants/user_constants";
// eslint-disable-next-line
import { GlobalContext } from "src/context/GlobalState";

const DailyLimitField = () => {
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  const createNewBox = {
    boxSizing: "border-box",
    width: "100%",
    borderWidth: "1px 1px 1px 1px",
    padding: "10px",
    borderStyle: "solid",
    borderColor: "#BFBFBF",
    borderRadius: "8px 8px 8px 8px",
    marginBottom: "1em"
  };

  const handleChange = (e) => {
    const val = e.target.value;
    const body = {
      maximumQty: val === "" ? null : parseInt(val)
    };
    dispatch({ type: "productRecord", payload: { ...productState?.productRecord, rules: { ...productState?.productRecord.rules, dailyLimit: body } } });
    if (productState.edit) {
      dispatch({ type: "edit", payload: true });
    } else {
      if (productState.addProduct) {
        dispatch({ type: "addProduct", payload: true });
      } else {
        dispatch({ type: "addVariant", payload: true });
      }
    }
  };

  return (
    <>
      <div style={createNewBox}>
        <p>
          <b>Daily Limit</b>
        </p>
        <FormControl fullWidth>
          <TextField
            disabled={isSeller}
            fullWidth
            label="Maximum"
            variant="standard"
            onChange={(e) => {
              handleChange(e);
            }}
            margin="normal"
            value={productState.productRecord?.rules?.dailyLimit?.maximumQty ?? null}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </div>
    </>
  );
};

export default DailyLimitField;
