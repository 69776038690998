import { useContext } from "react";
// eslint-disable-next-line

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";
import FirebaseApp from "src/firebase/firebase";

const EmptyProductImagesUpload = () => {
  const storage = getStorage(FirebaseApp);
  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((data) => {
        handleUploadMultipleGalleryImage(data);
      });
    }
  });

  const handleUploadMultipleGalleryImage = (image) => {
    const defaultFileSize = process.env.REACT_APP_FILESIZE_LIMIT;

    if (image?.size > defaultFileSize) {
      alert("Please upload product image less than 150kb?");
    } else {
      const Reference = ref(storage, `SKU/Brands/GalleryPhotos/${Date.now()}-${image.name}`);
      uploadBytes(Reference, image).then((snapshot) => {
        console.log(snapshot);
        getDownloadURL(Reference).then((url) => {
          const updatedImageUrls = [...(productState.productRecord?.productImageUrls || [])];
          updatedImageUrls.push({ url, isPrimary: false });
          dispatch({ type: "productRecord", payload: { ...productState.productRecord, productImageUrls: updatedImageUrls } });
        });
      });
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }} {...getRootProps()}>
      <input disabled={isSeller} {...getInputProps()} />
      <img src={"/assets/upload.png"} style={{ marginLeft: "auto", marginRight: "auto", marginTop: "80px" }} alt="Product" />
      <p>
        <b>Drag and Drop files here</b>
      </p>
      <p>
        click <a href="#">here</a> or drop to add product variant images
      </p>
    </div>
  );
};

export default EmptyProductImagesUpload;
