export const deliveryRequestStyle = {
  cartTitle: {
    fontSize: "22px",
    fontWeight: "700",
    fontFamily: "'Lato'",
    lineHeight: "normal",
    color: "#2C2C2C"
  },

  emptyCartText: {
    fontSize: "18px",
    fontWeight: "700",
    fontFamily: "'Lato'",
    lineHeight: "normal",
    color: "#404040"
  },

  cartInstruction: {
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "'Lato'",
    lineHeight: "normal",
    color: "#404040"
  },

  cartItem: {}
};
