export const alertInitialState = {
  showMessage: false,
  alertDetail: {
    title: null,
    heading: null,
    body: null,
    type: "warning"
  }
};

export const alertReducer = (state, action) => {
  switch (action.type) {
    case "showMessage":
      return { ...state, showMessage: action.payload };
    case "alertDetail":
      return { ...state, alertDetail: action.payload };
    case "alertClear":
      return { ...state, showMessage: false, alertDetail: { ...state.alertDetail, title: null, heading: null, body: null, type: "warning" } };
    default:
      return state;
  }
};
