import { Close, IndeterminateCheckBox } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Button, TableContainer, Table, TableHead, TableRow, TableBody, CircularProgress, TableCell } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/GlobalState";
import { pTable } from "src/styles/styles";
import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import { colors } from "src/constants/constants";
import { getComparator, stableSort } from "src/utils/sortTables";
import { fetchStatusLogs } from "src/api/orders";
import { statusCodes } from "src/utils/statusCodeUtils";
import { fDate, fDateV2 } from "src/utils/formatTime";

const StockLogsDialog = ({ orderId }) => {
  const [tableArray, setTableArray] = useState([]);
  const [orderBy, setOrderBy] = useState("updatedAt");
  const [order, setOrder] = useState("desc");
  const [loading, setLoading] = useState(false);

  const {
    dispatch,
    state: { orderState, alertState }
  } = useContext(GlobalContext);

  const fetchLogsData = async () => {
    try {
      setLoading(true);
      const response = await fetchStatusLogs(orderId);
      if (response.httpStatusCode === 200) {
        setLoading(false);
        setTableArray(response?.result?.data);
      } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
        setLoading(false);
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
        dispatch({ type: "showMessage", payload: true });
      } else {
        setLoading(false);
        alert(response.message);
      }
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };
  useEffect(() => {
    fetchLogsData();
  }, []);

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "User"
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: "Date & Time"
    },
    {
      id: "reason",
      numeric: false,
      disablePadding: true,
      label: "Reason"
    }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  console.log("rec", tableArray);

  return (
    <Dialog maxWidth="lg" sx={{ "& .MuiDialog-paper": { width: "950px" } }} open={orderState.showLogsDeliveredDialog} onClose={() => dispatch({ type: "showLogsDeliveredDialog", payload: false })}>
      <Grid sx={{ backgroundColor: "#F2F2F2", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DialogTitle>General Stock Logs</DialogTitle>
        <Close
          onClick={() => {
            dispatch({ type: "showLogsDeliveredDialog", payload: false });
          }}
          sx={{ mx: 1, cursor: "pointer" }}
        />
      </Grid>
      <DialogContent sx={{ px: 3, py: 2 }}>
        <Typography>Below are the latest logs on changes Delivery statuses</Typography>
        <TableContainer sx={{ my: 2 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={tableArray.length} style={{ whiteSpace: "nowrap" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <CircularProgress sx={{ color: colors.primary }} />
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(tableArray, getComparator(order, orderBy)).map((record) => (
                  <TableRow key={record.updatedTime}>
                    {/* eslint-disable-next-line */}
                    <TableCell align="left">
                      <p style={pTable}>
                        {record?.firstName ?? "-"} {record?.lastName ?? "-"}
                      </p>
                    </TableCell>
                    <TableCell align="left">
                      <p style={pTable}>{record.updatedTime ? fDateV2(record.updatedTime) : null}</p>
                    </TableCell>
                    <TableCell align="left">
                      <p style={pTable}>{record.reason}</p>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Grid fullWidth sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <Button
            onClick={() => dispatch({ type: "showLogsDeliveredDialog", payload: false })}
            sx={{
              color: "#FFF",
              backgroundColor: "#FE7C19",
              borderRadius: 2,
              px: 4,
              py: 1,
              "&:hover": {
                backgroundColor: "#FE7C19"
              }
            }}
          >
            Close
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default StockLogsDialog;
