import { Box, Popover, Typography } from "@mui/material";
import React, { useContext } from "react";
import StockLogsDialog from "../dialogs/StockLogsDialog";
import { GlobalContext } from "src/context/GlobalState";

const DeliveryLogsPopover = ({ openActionPopover, setOpenActionPopover, details }) => {
  const {
    dispatch,
    state: { orderState }
  } = useContext(GlobalContext);
  return (
    <>
      {orderState?.showLogsDeliveredDialog && <StockLogsDialog orderId={details} />}
      <Popover
        open={Boolean(openActionPopover)}
        anchorEl={openActionPopover}
        onClose={() => setOpenActionPopover(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            elevation: 0,
            boxShadow: "none",
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75
            }
          }
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            onClick={() => {
              setOpenActionPopover(null);
              dispatch({ type: "showLogsDeliveredDialog", payload: true });
            }}
            sx={{ m: 1, cursor: "pointer", fontSize: "14px" }}
          >
            View Delivery Status Logs
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default DeliveryLogsPopover;
