import { Autocomplete, Chip, FormControl, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
// eslint-disable-next-line
import styled from "@emotion/styled";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";
import FirebaseApp from "src/firebase/firebase";

const CategoryField = () => {
  const storage = getStorage(FirebaseApp);
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);

  const [inputValue, setInputValue] = useState("");

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((data) => {
        handleUploadImage(data);
      });
    }
  });

  const createNewBox = {
    boxSizing: "border-box",
    marginTop: "10px",
    width: "100%",
    background: "#F2F2F2",
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "#BFBFBF",
    borderRadius: "8px 8px 8px 8px"
  };

  const addVariantButton = {
    display: "flex",
    flexDirection: "row",
    color: "#404040",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    height: "40px",
    borderRadius: "8px",
    borderColor: "black",
    flex: "none",
    order: "1",
    flexGrow: "0"
  };

  const handleUploadImage = (image) => {
    const Reference = ref(storage, `SKU/Category/CategoryImages/${Date.now()}${image.name}`);
    uploadBytes(Reference, image).then((snapshot) => {
      console.log(snapshot);
      getDownloadURL(Reference).then((url) => {
        dispatch({ type: "newCategoryImgUrl", payload: url });
      });
    });
  };

  const handleChange = (event, newValue) => {
    if (newValue.length === 0) {
      alert("Category already added");
      return;
    }
    const newCategory = newValue.slice(-1)[0].categoryId;
    const result = productState.productRecord.mainCategories.filter((x) => x !== null).find(({ categoryId }) => categoryId === newCategory);
    if (result === undefined) {
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, mainCategories: newValue } });
    } else {
      alert("Category already added");
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleClose = () => {
    dispatch({ type: "newCategory", payload: false });
    dispatch({ type: "newCategoryName", payload: null });
    dispatch({ type: "newCategoryImgUrl", payload: null });
    dispatch({ type: "category", payload: null });
  };

  const handleConfirm = () => {
    const body = {
      categoryId: null,
      categoryName: productState.newCategoryName,
      categoryImageUrl: productState.newCategoryImgUrl
    };

    if (!body.categoryName || body.categoryName.trim().length === 0) {
      alert("Please enter category name");
      return;
    }
    if (!body.categoryImageUrl || body.categoryImageUrl.trim().length === 0) {
      alert("Please upload category image");
      return;
    }
    dispatch({ type: "productRecord", payload: { ...productState?.productRecord, mainCategories: [...productState.productRecord.mainCategories, body].filter((x) => x !== null) } });
    dispatch({ type: "newCategory", payload: false });
    dispatch({ type: "newCategoryName", payload: null });
    dispatch({ type: "newCategoryImgUrl", payload: null });
    dispatch({ type: "category", payload: null });

    handleClose();
  };

  // Define a styled component for bold items
  const BoldItem = styled("div")({
    fontWeight: "500"
  });

  // Define a styled component for group headers
  const GroupHeader = styled(Typography)({
    padding: "5px 10px",
    fontStyle: "italic",
    color: "grey",
    fontSize: "14px"
  });

  const getBreadcrumbLabel = (option) => {
    return option.group ? `${option.group} > ${option.categoryName}` : option.categoryName;
  };

  return (
    <>
      <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
        <Autocomplete
          disabled={isSeller}
          multiple
          value={productState.productRecord.mainCategories.filter((x) => x !== null || x !== undefined)}
          disableClearable={true}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          id="category-label"
          options={productState.showPromoCategoryDropdown ? productState.categoryDropdown : productState.categoryDropdown.filter((category) => category.categoryName !== "PROMO")}
          groupBy={(option) => option.group || ""}
          getOptionLabel={(option) => option.categoryName}
          renderInput={(params) => <TextField {...params} sx={{ py: 2 }} variant="standard" label="Category" placeholder="Add Category" />}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                sx={{ my: 1 }}
                label={getBreadcrumbLabel(option)}
                {...getTagProps({ index })}
                onDelete={() => {
                  dispatch({ type: "productRecord", payload: { ...productState?.productRecord, mainCategories: productState.productRecord.mainCategories.filter((val) => val !== option) } });
                }}
              />
            ))
          }
          renderOption={(props, option) => <li {...props}>{option.group ? option.categoryName : <BoldItem>{option.categoryName}</BoldItem>}</li>}
          renderGroup={(params) => [<GroupHeader key={params.key}>{params.group} </GroupHeader>, params.children]}
        />
      </FormControl>
    </>
  );
};

export default CategoryField;
