import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const header = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "16px 16px 16px 60px",
  gap: "16px",
  position: "absolute",
  width: "85%",
  left: "224px",
  top: "80px",
  background: "#F2F2F2"
};

export const actions = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "16px",
  gap: "16px",
  position: "absolute",
  width: "85%",
  height: "72px",
  left: "224px",
  top: "168px",
  background: "#FFFFFF"
};

export const actions2 = {
  display: "flex",
  flexDirection: "row",
  // justifyContent: "flex-end",
  alignItems: "center",
  position: "relative",
  width: "100%",
  height: "72px"
};

export const addProductButton = {
  display: "flex",
  flexDirection: "row",
  color: "white",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px",
  height: "40px",
  background: "#FE7C19",
  borderRadius: "8px",
  flex: "none",
  order: "1",
  flexGrow: "0"
};

export const statusButtons = {
  width: "80px"
  // height: "20px"
};

export const warningButton = {
  width: "30px"
  // height: "20px"
};

export const brandIcons = {
  width: "40px",
  // height: "20px"
  borderRadius: "20px"
};

export const filterButton = {
  color: "white",
  height: "40px",
  width: "100px",
  background: "#FE7C19",
  borderRadius: "8px"
};

export const circleAddIcon = {
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  background: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#FE7C19",
  fontSize: "14px"
};

export const filterButton2 = {
  color: "white",
  height: "54px",
  // width: "100px",
  background: "#FE7C19",
  borderRadius: "8px"
};

export const createRequestButton = {
  color: "white",
  height: "40px",
  background: "#FE7C19",
  borderRadius: "8px"
};

export const addVariantButton = {
  display: "flex",
  flexDirection: "row",
  color: "#404040",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px",
  height: "40px",
  borderRadius: "8px",
  borderColor: "black",
  flex: "none",
  order: "1",
  flexGrow: "0"
};

export const tableDiv = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "16px",
  position: "absolute",
  width: "85%",
  height: "65%",
  left: "224px",
  top: "240px"
};

export const dialogHeader = {
  width: "584px",
  height: "40px",
  left: "13px",
  top: "11px",
  marginTop: "10px",
  fontFamily: "'Lato'",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "19px",
  color: "#FFFFFF"
};

export const pVarText = {
  fontFamily: "'Lato'",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "19px"
};

export const categoryText = {
  fontFamily: "'Lato'",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "5px"
};

export const tableHeadText = {
  fontWeight: "700",
  fontSize: "12px",
  lineHeight: "0px",
  whiteSpace: "nowrap",
  display: "inline-flex",
  textAlign: "center",
  align: "center",
  alignItems: "center",
  flex: "none",
  order: "0",
  flexGrow: "0",
  color: "#000"
};

export const pTable = {
  // whiteSpace: "nowrap",
  lineHeight: "12px",
  fontSize: "12px",
  display: "inline-block"
};

export const imageContainer = {
  alignItems: "center",
  position: "relative",
  marginTop: "auto",
  width: "100%",
  height: "100%",
  background: "white",
  borderRadius: "4px"
};

export const brandImageContainer = {
  alignItems: "center",
  position: "relative",
  marginTop: "auto",
  width: "100%",
  height: "90%",
  background: "white",
  borderRadius: "4px"
};

export const categoryImageContainer = {
  alignItems: "center",
  position: "relative",
  width: "100%",
  height: "90%",
  background: "white",
  borderRadius: "4px"
};

export const brandCoverPhotoContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "16px",
  // gap: "8px",
  position: "relative",
  width: "100%",
  height: "90%",
  background: "#F2F2F2",
  borderRadius: "4px"
};

export const brandGalleryContainer = {
  // display: "flex",
  // flexDirection: "row",
  // alignItems: "flex-start",
  // padding: "16px",
  // gap: "8px",
  position: "relative",
  height: "180px",
  // height: "100%",
  background: "url(istockphoto-1285888022-612x612.jpg)",
  borderRadius: "4px"
};

export const imageContainer2 = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "16px",
  // gap: "8px",
  position: "relative",
  width: "120px",
  height: "120px",
  background: "url(istockphoto-1285888022-612x612.jpg)",
  borderRadius: "4px"
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFD4B3",
    color: "#000"
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    whiteSpace: "nowrap",
    display: "inline-flex",
    textAlign: "center",
    align: "center",
    alignItems: "center",
    color: "#404040",
    flex: "none",
    order: "0",
    flexGrow: "0"
  }
}));

export const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFD4B3",
    color: "#000",
    width: "250px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    textAlign: "center",
    align: "center",
    alignItems: "center",
    color: "#404040",
    flex: "none",
    order: "0",
    flexGrow: "0"
  }
}));

export const StyledTableCell3 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFD4B3",
    color: "green",
    width: "0px"
  }
}));

export const orderDetails = {
  width: "100%",
  padding: "40px"
};

export const orderDetailsBox1 = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  paddingLeft: "16px",
  paddingRight: "16px",
  gap: "8px",
  width: "100%",
  bottom: "15px",
  height: "79px",
  background: "#FFFFFF",
  border: "1px solid #D9D9D9",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  flex: "none",
  order: "0",
  flexGrow: "0"
};

export const orderDetailsBox2 = {
  boxSizing: "border-box",
  // display: "flex",
  // flexDirection: "row",
  // alignItems: "flex-start",
  paddingLeft: "16px",
  paddingRight: "16px",
  gap: "8px",
  width: "100%",
  height: "300px",
  background: "#FFFFFF",
  border: "1px solid #D9D9D9",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  flex: "none",
  order: "0",
  flexGrow: "0"
};

export const orderDetailsBox3 = {
  boxSizing: "border-box",
  // display: "flex",
  // flexDirection: "row",
  // alignItems: "flex-start",
  width: "100%",
  position: "relative",
  top: "15px",
  height: "283px",
  background: "#FFFFFF",
  border: "1px solid #D9D9D9",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  order: "0",
  padding: "16px"
};

export const orderDetailsHeading = {
  fontSize: "10px",
  color: "#8C8C8C",
  lineHeight: "20px"
};

export const orderDetailsText = {
  fontSize: "14px"
  // lineHeight: "20px",
};

export const pamojaBox = {
  // boxSizing: "border-box",
  // display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  paddingLeft: "8px",
  paddingRight: "8px",
  paddingTop: "8px",
  paddingBottom: "8px",
  gap: "8px",
  width: "100%",
  bottom: "15px",
  // height: "90px",
  border: "1px solid #D9D9D9",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  flex: "none",
  order: "0",
  flexGrow: "0"
};

export const pamojaDetailsHeading = {
  fontSize: "14px",
  color: "#8C8C8C",
  lineHeight: "0px"
};

export const pamojaDetailsHeading2 = {
  fontSize: "12px",
  color: "#8C8C8C",
  lineHeight: "0px"
};

export const crmDetailsText = {
  fontSize: "12px"
  // lineHeight: "20px"
};

export const orderDetailsCustomerName = {
  fontSize: "18px",
  fontWeight: "700px",
  color: "#59B7B2",
  lineHeight: "22px"
};

export const orderDetailsCustomerName2 = {
  fontSize: "14px",
  color: "#59B7B2",
  lineHeight: "22px",
  cursor: "pointer"
};

export const crmDetailsBox = {
  boxSizing: "border-box",
  padding: "16px",
  paddingBottom: "16px",
  paddingRight: "16px",
  gap: "8px", // gap: "8px",
  width: "100%",
  height: "315px",
  background: "#FFFFFF",
  border: "1px solid #D9D9D9",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  flex: "none",
  order: "0",
  flexGrow: "0"
};

export const addressBox = {
  padding: "16px",
  border: "1px solid #D9D9D9",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  height: "100%"
};

export const crmHeaderBox1 = {
  width: "100%",
  height: "47px",
  // position: 'relative',
  top: "0",
  left: "0",
  // bottom: "12px",
  // gap: "8px",
  paddingBottom: "60px",
  paddingLeft: "5px",
  // paddingTop: "0px",
  background: "#F2F2F2",
  border: "1px solid #D9D9D9",
  borderRadius: "8px",
  alignItems: "center"
  // justifyContent: "center",
  // align: "center",
  // alignSelf: "center"
};

export const crmHeaderBox2 = {
  width: "100%",
  height: "36px",
  position: "relative",
  top: "8px",
  bottom: "12px",
  gap: "8px",
  // paddingBottom: "50px",
  paddingLeft: "5px",
  paddingTop: "0px",
  // background: "#F2F2F2",
  // border: "1px solid #D9D9D9",
  // borderRadius: "8px 8px 8px 8px",
  alignItems: "center",
  justifyContent: "center",
  align: "center",
  alignSelf: "center"
};

export const pmDetails = {
  width: "100%",
  paddingLeft: "40px"
};

export const pmDetailsHeading = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  paddingLeft: "16px",
  paddingRight: "16px",
  gap: "8px",
  width: "100%",
  bottom: "15px",
  background: "#F2F2F2",
  flex: "none",
  order: "0",
  flexGrow: "0"
};

export const pmDetailsHeadingText = {
  fontFamily: "'Lato'",
  fontStyle: "normal",
  marginBottom: "20px",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "14px",
  display: "flex",
  alignItems: "center",
  color: "#404040",
  flex: "none",
  order: "0",
  flexGrow: "0"
};

export const pmProductDetails = {
  // height: "118px",
  width: "100%",
  padding: "8px"
};

export const pmSokoDiscount = {
  // height: "190px",
  width: "100%",
  padding: "15px"
};

export const pmConsumerMarket = {
  // height: "456px",
  width: "100%",
  padding: "8px"
};

export const pmUserFeedback = {
  // height: "90px",
  width: "100%",
  padding: "8px"
};

export const pmButtons = {
  height: "67px",
  width: "100%"
};

export const addButtonText = {
  whiteSpace: "nowrap",
  display: "inline",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "14px",
  color: "#8DA3BF"
};

// JIPANGE
export const jipangeHeading = {
  fontFamily: "'Lato'",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  fontSize: "22px",
  color: "#404040"
};

export const jipangeSubTitle = {
  fontFamily: "'Lato'",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  fontSize: "18px"
};
export const jipangeMiniTitle = {
  fontFamily: "'Lato'",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  fontSize: "16px",
  color: "#8C8C8C"
};

export const jipangeContainer = {
  marginTop: "1em",
  marginBottom: "1em",
  paddingTop: "1em",
  paddingBottom: "1em",
  borderRadius: "1em",
  border: "1px solid #BFBFBF"
};

export const jipangeText = {
  fontFamily: "Lato",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal"
};

export const jipangeTextWidth = {
  width: "50px"
};

export const jipangeButtonContainers = {
  display: "flex",
  paddingRight: "1em"
};
