import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useDeliveryRequest } from "../context/deliveryRequestContext";
import pickupIcon from "../svg/pick-up.svg";
import GoogleMaps from "./google_maps";

function Pickup() {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const { state, dispatch } = useDeliveryRequest();

  // Handle place selection from the Autocomplete options
  const handleOptionSelect = async (event, selectedOption) => {
    try {
      dispatch({
        type: "UPDATE_PICKUP",
        payload: {
          city: selectedOption.city,
          street: selectedOption.street ?? "",
          latitude: `${selectedOption.lat}`,
          longitude: `${selectedOption.lng}`,
          landMark: selectedOption.landMark ?? null,
          googlePin: selectedOption.googlePin ?? "",
          pincode: selectedOption.pincode ?? "",
          state: selectedOption.state ?? "",
          country: selectedOption.country,
          houseNumber: selectedOption.houseNumber ?? null
        }
      });
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  return (
    <Grid item xs={12} sx={{ mb: 2, position: "relative" }}>
      {/* Icon container with relative positioning to manage stacking */}
      <Grid
        style={{
          display: "flex",
          gap: "10px",
          verticalAlign: "middle",
          alignItems: "center",
          position: "relative",
          zIndex: 10
        }}
      >
        <img src={pickupIcon} alt="pickup" style={{ width: "40px", height: "40px" }} />
        <p style={{ fontSize: "16px", fontWeight: "700" }}>Pickup</p>
      </Grid>
      {/* Pickup content container */}
      <Grid
        style={{
          padding: "20px 50px"
        }}
      >
        <Grid
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "16px",
            padding: "32px",
            display: "flex",
            flexDirection: "column",
            gap: "24px"
          }}
        >
          <GoogleMaps onPlaceSelect={(placeDetails) => handleOptionSelect(null, placeDetails)} />

          <TextField
            sx={{ marginTop: 0 }}
            fullWidth
            label="Apartment, Estate, or Landmark"
            margin="normal"
            variant="standard"
            onChange={(e) => {
              dispatch({
                type: "UPDATE_PICKUP",
                payload: {
                  landMark: e.target.value,
                  houseNumber: e.target.value
                }
              });
            }}
            required
          />
          <TextField
            sx={{ marginTop: 0 }}
            fullWidth
            label="Dispatch Agent Phone number"
            margin="normal"
            variant="standard"
            onChange={(e) =>
              dispatch({
                type: "UPDATE_ORDER_DATA",
                payload: {
                  pickupContactNumber: e.target.value
                }
              })
            }
            required
          />
          <TextField
            sx={{ marginTop: 0 }}
            fullWidth
            value={state.orderData.pickupInstruction}
            label="Pickup Instructions (optional)"
            margin="normal"
            variant="standard"
            onChange={(e) => {
              dispatch({
                type: "UPDATE_ORDER_DATA",
                payload: {
                  pickupInstruction: e.target.value || null
                }
              });
            }}
          />
        </Grid>
      </Grid>
      {/* Dotted vertical line */}
      <div
        style={{
          position: "absolute",
          top: "20px",
          left: "20px", // Adjust to align with the pickup icon
          height: "calc(100% - 20px)", // Extends the full height of the component
          borderLeft: "1px dashed #000",
          zIndex: 0 // Ensure dotted line is behind the icon
        }}
      />
    </Grid>
  );
}

export default Pickup;
