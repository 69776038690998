import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useContext } from "react";
import { isUserSeller } from "src/constants/user_constants";
// eslint-disable-next-line
import { GlobalContext } from "src/context/GlobalState";
import { deliveryBatchTypes } from "src/pages/products/constants/product.constant";

const SKUField = () => {
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  const createNewBox = {
    boxSizing: "border-box",
    marginTop: "10px",
    width: "100%",
    background: "#F2F2F2",
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "#BFBFBF",
    borderRadius: "8px 8px 8px 8px"
  };

  const addVariantButton = {
    display: "flex",
    flexDirection: "row",
    color: "#404040",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    height: "40px",
    borderRadius: "8px",
    borderColor: "black",
    flex: "none",
    order: "1",
    flexGrow: "0"
  };

  const handleChange = (e) => {
    if (e.target.name === "sku" && e.target.value === "POST") {
      dispatch({ type: "newSKU", payload: true });
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, skuTypeId: null } });
    } else if (e.target.value === 1000) {
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, skuTypeId: parseInt(e.target.value), unitId: parseInt(process.env.REACT_APP_DP_UNIT), deliveryBatchId: deliveryBatchTypes.digitalDelivery } });
    } else {
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, skuTypeId: parseInt(e.target.value), additionalDetails: null } });
    }
  };

  const handleClose = () => {
    dispatch({ type: "newSKU", payload: false });
    dispatch({ type: "newSKUName", payload: null });
    dispatch({ type: "productRecord", payload: { ...productState?.productRecord, skuTypeId: null, skuTypeName: null } });
  };

  return (
    <>
      {productState.newSKU ? (
        <div style={createNewBox}>
          <p>Create New SKU Type</p>
          <FormControl fullWidth>
            <TextField
              disabled={isSeller}
              fullWidth
              label="SKU Name"
              value={productState.productRecord?.skuTypeName}
              variant="standard"
              margin="normal"
              onChange={(e) => {
                dispatch({ type: "productRecord", payload: { ...productState?.productRecord, skuTypeName: e.target.value } });
              }}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <div
            style={{
              height: "42px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Button onClick={(e) => handleClose()} variant="outlined" style={addVariantButton}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <FormControl fullWidth margin="dense" sx={{ mb: 1 }}>
          <InputLabel sx={{ ml: -1.5, mt: 1 }} id="sku-label">
            SKU Type
          </InputLabel>
          <Select disabled={isSeller} labelId="sku-label" value={productState.productRecord?.skuTypeId ?? ""} name="sku" label="SKU Name" variant="standard" onChange={(e) => handleChange(e)} InputLabelProps={{ shrink: true }}>
            <MenuItem value="POST">Create New SKU Type</MenuItem>
            {productState.skuTypeDropdown.map((data) => (
              <MenuItem value={data?.skuTypeId}>{data.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SKUField;
