import { markedDeliveredReasons } from "src/constants/order_constants";

export const markOrderDeliveredValidator = ({ orderObj }) => {
  try {
    const reqBody = orderObj;
    if (!reqBody.orderId || !reqBody.statusId) return { error: "Please select orderID" };
    if (!reqBody?.reason) return { error: "Please select reason" };
    if (reqBody?.reason === markedDeliveredReasons[1] && !reqBody.anotherOrderId) return { error: "Please select the different orderID" };

    if (reqBody?.reason === markedDeliveredReasons[3] && !reqBody.anotherReason) return { error: "Please input another reason" };
    if (reqBody?.reason === markedDeliveredReasons[3] && reqBody.anotherReason) reqBody.reason = reqBody.anotherReason;
    if (reqBody?.reason !== markedDeliveredReasons[3]) reqBody.anotherReason = null;
    if (reqBody?.reason !== markedDeliveredReasons[1]) reqBody.anotherOrderId = null;

    delete reqBody.anotherReason;

    return reqBody;
  } catch (error) {
    return { error: error?.message };
  }
};
