import { Grid, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useDeliveryRequest } from "../context/deliveryRequestContext";
import dropOffIcon from "../svg/drop-off.svg";
import GoogleMaps from "./google_maps";

function DropOff() {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useDeliveryRequest();
  const [placeDetails, setPlaceDetails] = useState({
    city: "",
    street: "",
    lat: 0,
    lng: 0,
    postalCode: "",
    googlePlaceId: "",
    country: "Kenya",
    administrativeArea: "",
    landMark: ""
  });

  const handleOptionSelect = async (event, selectedOption) => {
    try {
      dispatch({
        type: "UPDATE_DROPOFF",
        payload: {
          city: selectedOption.city,
          street: selectedOption.street ?? "",
          latitude: `${selectedOption.lat}`,
          longitude: `${selectedOption.lng}`,
          landMark: selectedOption.landMark ?? null,
          googlePin: selectedOption.googlePin ?? "",
          pincode: selectedOption.pincode ?? "",
          state: selectedOption.state ?? "",
          country: selectedOption.country,
          houseNumber: selectedOption.houseNumber ?? null
          // ...other details
        }
      });
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Grid style={{ display: "flex", gap: "10px", verticalAlign: "middle", alignItems: "center" }}>
        <img src={dropOffIcon} alt="drop-off" style={{ width: "40px", height: "40px" }} />
        <p style={{ fontSize: "16px", fontWeight: "700" }}>Drop Off</p>
      </Grid>
      <Grid
        style={{
          padding: "20px 50px"
        }}
      >
        <Grid
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "16px",
            padding: "32px",
            display: "flex",
            flexDirection: "column",
            gap: "24px"
          }}
        >
          <GoogleMaps onPlaceSelect={(placeDetails) => handleOptionSelect(null, placeDetails)} />
          <Grid container spacing={2} direction="row" alignItems="center" justifyContent="space-between">
            <Grid item xs={6}>
              <TextField
                sx={{ marginTop: 0 }}
                fullWidth
                required
                label="Customer First Name"
                margin="normal"
                variant="standard"
                onChange={(e) => {
                  dispatch({
                    type: "UPDATE_FIRST_NAME",
                    payload: {
                      firstName: e.target.value
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ marginTop: 0 }}
                fullWidth
                required
                label="Customer Last Name"
                margin="normal"
                variant="standard"
                onChange={(e) => {
                  dispatch({
                    type: "UPDATE_LAST_NAME",
                    payload: {
                      lastName: e.target.value
                    }
                  });
                }}
              />
            </Grid>
          </Grid>
          <TextField
            sx={{ marginTop: 0 }}
            fullWidth
            required
            label="Apartment, Estate, or Landmark"
            margin="normal"
            variant="standard"
            onChange={(e) =>
              dispatch({
                type: "UPDATE_DROPOFF",
                payload: {
                  landMark: e.target.value,
                  houseNumber: e.target.value
                }
              })
            }
          />
          <TextField
            sx={{ marginTop: 0 }}
            fullWidth
            required
            label="Customer Phone number"
            margin="normal"
            variant="standard"
            onChange={(e) => {
              dispatch({
                type: "UPDATE_PHONE_NUMBER",
                payload: {
                  phoneNumber: e.target.value
                }
              });
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">+254</InputAdornment>
            }}
          />
          <TextField
            sx={{ marginTop: 0 }}
            fullWidth
            label="Delivery Instructions (optional)"
            margin="normal"
            variant="standard"
            onChange={(e) => {
              dispatch({
                type: "UPDATE_ORDER_DATA",
                payload: {
                  deliveryInstruction: e.target.value || null
                }
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DropOff;
