import axios from "axios";

export const addDeliveryRequest = async (body) => {
  console.log({ body });
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BASE_URL}/api/orderService/admin/order/create`;
  console.log({ token });

  try {
    const response = await axios.post(url, body, {
      headers: {
        Accept: "application/json",
        authorization: token
      }
    });
    return response.data; // Assuming you want to return the response data
  } catch (error) {
    console.log({ error });
    const displayMessage = error.response?.data?.displayMessage || "An error occurred";
    console.error("Error in addDeliveryRequest:", displayMessage);
    throw displayMessage;
  }
};
