export const login = async (body) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/userService/admin/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const resetPassword = async (body) => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  console.log({ body });
  const onboardedBody = {
    userId: user.userId,
    features: {
      adminOnboarded: true
    }
  };

  console.log({ onboardedBody });
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/userService/admin/user/password/update`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });

    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/userService/user/save/featureDiscovery`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token
        },
        body: JSON.stringify(onboardedBody)
      });
    } catch (onboardingError) {
      console.log({ onboardingError });
      throw onboardingError;
    }

    return response.json();
  } catch (error) {
    return error;
  }
};
