import { Add, Close, Remove } from "@mui/icons-material";
import { Avatar } from "@mui/material";

function CartItem({ item, increaseQty, decreaseQty, removeItem }) {
  return (
    <div className="bg-white w-full  p-[16px] border border-[#D9D9D9] rounded-[8px] flex flex-col gap-[8px] shadow-sm">
      <div className="flex items-start gap-[8px] w-full">
        <Avatar
          src={item.productVariantImage?.[0]?.imageUrl}
          alt={item.product.name}
          sx={{ width: 40, height: 40, borderRadius: 1 }} // Square with rounded corners
        />
        <div className="flex flex-col w-full">
          <div className="flex justify-between w-full">
            <p>
              {item.brand.name} {item.product.name}
            </p>
            <div className="" onClick={() => removeItem(item.productVariantId)}>
              <Close color="#2c2c2c" sx={{ fontSize: "16px", cursor: "pointer" }} />
            </div>
          </div>
          <p className="text-[14px] text-[#777777]">{item.unit.name}</p>
        </div>
      </div>
      <div className="w-full flex justify-end">
        <div className="flex gap-[8px]">
          <div className="w-[32px] h-[32px] bg-[#EF7B22] rounded-bl-[8px] rounded-tl-[8px] flex justify-center items-center" onClick={() => decreaseQty(item.productVariantId)}>
            <Remove color="#fff" sx={{ fontSize: "24px", color: "#fff", cursor: "pointer", fontWeight: "bold" }} />
          </div>
          <div className="w-[44px] flex justify-center items-center">
            <p className="text-black text-[18px] font-normal">{item.quantity}</p>
          </div>
          <div className="w-[32px] h-[32px] bg-[#EF7B22] rounded-br-[8px] rounded-tr-[8px] flex justify-center items-center cursor-pointer" onClick={() => increaseQty(item.productVariantId)}>
            <Add color="#fff" sx={{ fontSize: "24px", color: "#fff", cursor: "pointer", fontWeight: "bold" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
