import { Autocomplete, Box, CircularProgress, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { debounce, valuesIn } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/GlobalState";
import { searchOrderDetails } from "src/api/orders";
import orderStyle from "src/styles/orderStyle";
import { fDate } from "src/utils/formatTime";

const DeliveredDifferentOrder = ({ orderId }) => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    dispatch,
    state: { orderState }
  } = useContext(GlobalContext);

  const debouncedFetchOrderDetails = useCallback(
    debounce(async (input) => {
      if (input.length >= 3) {
        setLoading(true);
        try {
          const result = await searchOrderDetails(10, 0, input);
          if (result?.result?.data?.orders) {
            setSearchResults(result.result.data.orders);
          } else {
            setSearchResults([]);
          }
        } catch (error) {
          console.error("Error fetching order details:", error);
          setSearchResults([]);
        }
        setLoading(false);
      } else {
        setSearchResults([]);
      }
    }, 300),
    []
  );
  useEffect(() => {
    if (inputValue.length >= 3) {
      debouncedFetchOrderDetails(inputValue);
    } else {
      setSearchResults([]);
    }
  }, [inputValue, debouncedFetchOrderDetails]);

  const renderHighlightedText = (name, searchTerm) => {
    if (!searchTerm) return name;
    const regex = new RegExp(`(${searchTerm})`, "i");
    const parts = name.split(regex);
    return parts.map((part, index) => {
      if (part.toLowerCase() === searchTerm.toLowerCase()) {
        return (
          <Box key={index} component="span" sx={{ fontWeight: "bold", display: "inline" }}>
            {part}
          </Box>
        );
      } else {
        return (
          <Box key={index} component="span" sx={{ fontWeight: "normal", display: "inline" }}>
            {part}
          </Box>
        );
      }
    });
  };

  const handleOptionSelect = (event, option) => {
    if (option) {
      const res = searchResults.find((result) => result?.customerOrderId === option);
      setSelectedOrder(res);
      dispatch({ type: "orderStatusUpdateRecord", payload: { ...orderState?.orderStatusUpdateRecord, orderId: parseInt(orderId), anotherOrderId: res.customerOrderId } });
    }
  };

  return (
    <Grid sx={{ my: 2, flexGrow: 1, gap: "24px", display: "flex", flexDirection: "column" }}>
      <Autocomplete
        freeSolo
        id="order-search"
        disableClearable
        options={searchResults.map((option) => `${option.customerOrderId}`)}
        loading={loading}
        inputValue={inputValue}
        value={value}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, value) => {
          handleOptionSelect(event, value);
        }}
        renderOption={(props, option, { index }) => {
          delete props.className;
          return (
            <div className={`bg-white w-full p-[16px] flex items-center cursor-pointer h-[62px] ${index < searchResults.length - 1 ? "border-b border-b-[#D9D9D9]" : ""}`} {...props}>
              <p className="ml-[8px] text-[14px] font-normal">{renderHighlightedText(option, inputValue)}</p>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Order ID"
            variant="outlined"
            sx={{
              backgroundColor: "white",
              borderRadius: "8px"
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )}
                </>
              )
            }}
          />
        )}
      />
      {searchResults.length === 0 ? null : (
        <>
          <Grid container sx={{ display: "flex", gap: 2, justifyContent: "flex-start" }}>
            <Grid item xs={5} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography style={{ ...orderStyle.subTitle, fontSize: "16px" }}>Delivery Date</Typography>
              <Typography>{selectedOrder?.deliveryDate ? new Date(selectedOrder?.deliveryDate).toISOString().split("T")[0] : null}</Typography>
            </Grid>
            <Grid item xs={5} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography style={{ ...orderStyle.subTitle, fontSize: "16px" }}>Customer Name</Typography>
              <Typography>{selectedOrder?.name ?? null}</Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DeliveredDifferentOrder;
