import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchPMDetails, updatePMRecord } from "src/api/products";
import Loader from "src/components/dialogs/Loader";
import NewPMField from "src/components/dialogs/NewPMField";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";
import headerStyle from "src/styles/headerStyle";
import { actions2, addButtonText, addProductButton, addVariantButton, imageContainer2, orderDetailsText, pmConsumerMarket, pmDetails, pmDetailsHeading, pmDetailsHeadingText, pmProductDetails, pmSokoDiscount, pmUserFeedback } from "src/styles/styles";
import { fDate } from "src/utils/formatTime";
import { statusCodes } from "src/utils/statusCodeUtils";
import { priceMatrixCreateOrUpdateValidator } from "src/validators/product.validation";

const PriceDetails = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("search") || null;

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  const {
    dispatch,
    state: { pmState, alertState }
  } = useContext(GlobalContext);
  // eslint-disable-next-line
  const [lowestPrice, setLowestPrice] = useState(pmState.matrixPrice);
  // eslint-disable-next-line
  const [changesArray, setChangesArray] = useState([]);

  let { productVariantId } = useParams();
  const navigate = useNavigate();
  let matrixP;

  if (pmState.priceMatrixRecord?.discount === null) {
    matrixP = Math.round(pmState.matrixPrice * (100 - pmState.priceMatrixRecord.percentage) * 0.01);
  } else {
    matrixP = Math.round(pmState.matrixPrice * (100 - pmState.priceMatrixRecord?.discount) * 0.01);
  }

  useEffect(() => {
    fetchPriceMatrixDetailsById();
  }, [productVariantId]);

  const handleGetPriceMatrix = (obj) => {
    let prices = [];
    Object.keys(obj).forEach((key) => {
      if (key !== "WholeSale" && key !== "UserFeedback Prices") {
        const priceArray = obj[key];
        if (Array.isArray(priceArray)) {
          priceArray.forEach((data) => {
            if (data.price !== 0) {
              prices.push(data.price);
            }
          });
        }
      }
    });
    const sortedPrices = prices.sort((a, b) => a - b);
    dispatch({ type: "matrixPrice", payload: sortedPrices[0] });
  };

  const fetchPriceMatrixDetailsById = async () => {
    try {
      dispatch({ type: "loading", payload: true });
      const response = await fetchPMDetails(productVariantId);
      if (response.httpStatusCode === 200) {
        const { price, product, categories, skuType, brand, unit, variantType, image, overRidePrice, priceMatrixId, percentage, marketPrices, status, createdAt } = response.result.data?.priceMatrices;
        dispatch({
          type: "priceMatrixRecord",
          payload: {
            ...pmState?.priceMatrixRecord,
            productId: product?.productId ?? null,
            productVariantId: Number(productVariantId),
            marketPrices,
            discount: percentage,
            overRidePrice,
            priceMatrixId,
            price,
            imageUrl: image[0]?.imageUrl ?? "",
            productName: product?.name,
            variantName: variantType?.name ?? "",
            categoryName: categories[0]?.categoryName ?? "",
            skuTypeName: skuType?.name ?? "",
            brandName: brand?.name,
            unitName: unit?.name ?? "",
            percentage,
            status,
            createdAt
          }
        });
        handleGetPriceMatrix(marketPrices);
        dispatch({ type: "loading", payload: false });
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message ?? "Unable to fetch user details");
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  const cmPrices = ["Dukas", "Bargain Markets", "SuperMarkets", "Competition"];

  const handleChangePercentage = (e) => {
    dispatch({ type: "priceMatrixRecord", payload: { ...pmState.priceMatrixRecord, percentage: e.target.value, discount: Number(e.target.value) } });
  };

  const handleChangeOverride = (e) => {
    dispatch({ type: "priceMatrixRecord", payload: { ...pmState.priceMatrixRecord, overRidePrice: Number(e.target.value) === 0 || e.target?.value?.trim() === 0 || !e.target?.value ? 0 : Number(e.target.value) } });
  };

  const handleChange = (e, field, marketPriceTypeId) => {
    const uniqId = `id:${e.target.id}`;
    console.log("Rec23", uniqId, e.target.name);
    const updatedMarketPrices = pmState.priceMatrixRecord.marketPrices[e.target.name].map((data) => {
      if ((e.target.id === null && data.marketPriceId === null) || parseInt(e.target.id) === parseInt(data.marketPriceId)) {
        return { ...data, price: parseInt(e.target.value) };
      }
      return data;
    });

    const updatedPriceMatrixRecord = {
      ...pmState.priceMatrixRecord,
      marketPrices: {
        ...pmState.priceMatrixRecord.marketPrices,
        [e.target.name]: updatedMarketPrices
      }
    };

    if (e.target.id === null) {
      const updatedNewFields = pmState.newFields.map((data) => {
        if (data.marketPriceName === field) {
          return { ...data, marketPrice: e.target.value };
        }
        return data;
      });

      dispatch({
        type: "updateNewFields",
        payload: updatedNewFields
      });
    } else {
      const newChangesArray = [...changesArray];
      const changeIndex = newChangesArray.findIndex((change) => change.marketPriceId === parseInt(e.target.id));

      if (changeIndex === -1) {
        newChangesArray.push({
          marketPriceId: parseInt(e.target.id),
          marketPrice: parseInt(e.target.value),
          marketPriceTypeId: parseInt(marketPriceTypeId),
          marketPriceName: field
        });
      } else {
        newChangesArray[changeIndex].marketPrice = parseInt(e.target.value);
      }
      setChangesArray(newChangesArray);
    }

    dispatch({
      type: "priceMatrixRecord",
      payload: updatedPriceMatrixRecord
    });
  };

  const handleAddNewField = (category, marketPriceTypeId) => {
    dispatch({ type: "marketPriceTypeId", payload: marketPriceTypeId });
    dispatch({ type: "marketPriceName", payload: category });
    dispatch({ type: "addNewField", payload: true });
  };

  const handleConfirm = async () => {
    try {
      const validateResp = priceMatrixCreateOrUpdateValidator({ priceMatrixObj: pmState.priceMatrixRecord });
      console.log({ validateResp });
      if (validateResp?.error) alert(validateResp?.error);

      dispatch({ type: "loading", payload: true });
      validateResp.marketPrices = [...validateResp.marketPrices, ...changesArray, ...pmState.newFields];
      validateResp.matrixPrice = matrixP;

      const response = await updatePMRecord(validateResp);
      if (response.httpStatusCode === 200) {
        dispatch({ type: "loading", payload: false });
        alert(response?.message);
        dispatch({ type: "clear" });
        navigate(`/dashboard/priceMatrix${searchQuery ? `?search=${searchQuery}` : ""}`);
      } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
        dispatch({ type: "loading", payload: false });
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
        dispatch({ type: "showMessage", payload: true });
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message);
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  const handleUpdatePriceMatrix = (e) => {
    let prices = [];
    const obj = pmState.priceMatrixRecord.marketPrices;
    Object.keys(obj).forEach((key) => {
      if (key !== "WholeSale" && key !== "UserFeedback Prices") {
        const priceArray = obj[key];
        if (Array.isArray(priceArray)) {
          priceArray.forEach((data) => {
            if (data.price !== 0) {
              prices.push(data.price);
            }
          });
        }
      }
    });
    const sortedPrices = prices.sort((a, b) => a - b);
    dispatch({ type: "matrixPrice", payload: sortedPrices[0] });
  };

  return (
    <>
      <Loader />
      <div style={pmDetails}>
        <Grid container spacing={1} sx={{ mt: 0, mx: 2 }} style={headerStyle.headerContainer}>
          <Grid item xs={4}>
            <Typography variant="h4">Pricing Matrix</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{ mr: 1 }}
              onClick={() => {
                dispatch({ type: "clear" });
                navigate(`/dashboard/priceMatrix${searchQuery ? `?search=${searchQuery}` : ""}`, { replace: true });
              }}
            >
              <img alt="add" src="/assets/icons/button/back.svg" />
            </Button>
          </Grid>
        </Grid>
        <NewPMField />
        <div style={pmDetailsHeading}>
          <p style={pmDetailsHeadingText}>Product Details</p>
        </div>
        <div style={pmProductDetails}>
          <Grid container spacing={2}>
            <Grid item xs={1.5}>
              <img src={pmState.priceMatrixRecord.imageUrl} style={imageContainer2} alt="product" />
            </Grid>
            <Grid item xs={1.5}>
              <p style={orderDetailsText}>
                <b>{pmState.priceMatrixRecord.productName}</b>
              </p>
              <p>
                <b>{pmState.priceMatrixRecord.variantName}</b>
              </p>
            </Grid>
            <Grid item xs={2}>
              <p style={orderDetailsText}>
                <b>{pmState.priceMatrixRecord.categoryName}</b>
              </p>
              <p>
                <b>{pmState.priceMatrixRecord.unitName}</b>
              </p>
            </Grid>
            <Grid item xs={2}>
              <p style={orderDetailsText}>
                <b>{pmState.priceMatrixRecord.skuTypeName}</b>
              </p>
              <p>
                <b>{pmState.priceMatrixRecord.brandName}</b>
              </p>
            </Grid>
            <Grid item xs={1.5}>
              <p style={orderDetailsText}>
                <b>{pmState.priceMatrixRecord.priceMatrixId}</b>
              </p>
              <p>
                <b>{pmState.priceMatrixRecord.status}</b>
              </p>
            </Grid>
            <Grid item xs={3.5}>
              <p style={orderDetailsText}>
                <b>Ksh {pmState.priceMatrixRecord.price}</b>
              </p>
              <p>
                <b>{fDate(pmState.priceMatrixRecord.createdAt)}</b>
              </p>
            </Grid>
          </Grid>
        </div>
        <div style={pmDetailsHeading}>
          <p style={pmDetailsHeadingText}>SOKO Discount Percentage</p>
        </div>
        <div style={pmSokoDiscount}>
          <div style={{ height: "65px" }}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextField disabled id="matrix" label="Matrix Price" value={matrixP} />
              </Grid>
              <Grid item xs={2}>
                <TextField disabled={isSeller} id="discountPerc" label="Discount Percentage" onChange={(e) => handleChangePercentage(e)} defaultValue={pmState.priceMatrixRecord.percentage} value={pmState.priceMatrixRecord.percentage} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={2}>
                <TextField disabled={isSeller} id="override" label="Override Price" onChange={(e) => handleChangeOverride(e)} value={pmState.priceMatrixRecord.overRidePrice} InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "100px", marginTop: "20px" }}>
            <p style={pmDetailsHeadingText}>Wholesale</p>
            <Grid container spacing={2}>
              {pmState.priceMatrixRecord.marketPrices &&
                pmState.priceMatrixRecord.marketPrices.WholeSale &&
                pmState.priceMatrixRecord.marketPrices?.WholeSale.map((data, index) => (
                  <Grid item xs={2}>
                    <TextField disabled={isSeller} id={data.marketPriceId === null ? null : data.marketPriceId.toString()} label={data.name} name={data?.marketPriceType?.name ?? "WholeSale"} fullWidth onChange={(e) => handleChange(e, data.name, data.marketPriceTypeId)} onBlur={(e) => handleUpdatePriceMatrix(e)} defaultValue={data.price} />
                    {index === 0 && !isSeller ? (
                      <Button
                        onClick={() => {
                          handleAddNewField("WholeSale", data.marketPriceTypeId);
                        }}
                        style={{ height: "5px" }}
                        startIcon={<img alt="add" src="/assets/icons/button/add_circle.svg" />}
                      >
                        <p style={addButtonText}>Add New Field</p>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
        {!isSeller && (
          <>
            <div style={pmDetailsHeading}>
              <p style={pmDetailsHeadingText}>Consumer Market Prices</p>
            </div>
            <div style={pmConsumerMarket}>
              {cmPrices.map((cmPricetype) => (
                <>
                  <p style={pmDetailsHeadingText}>{cmPricetype}</p>
                  <Grid container spacing={2}>
                    {pmState.priceMatrixRecord.marketPrices &&
                      pmState.priceMatrixRecord.marketPrices[cmPricetype] &&
                      pmState.priceMatrixRecord?.marketPrices[cmPricetype]?.map((data, index) => (
                        <Grid item xs={2}>
                          <TextField id={data.marketPriceId === null ? null : data.marketPriceId.toString()} label={data.name} name={data.marketPriceType.name} onChange={(e) => handleChange(e, data.name, data.marketPriceType.marketPriceTypeId)} onBlur={(e) => handleUpdatePriceMatrix(e)} defaultValue={data.price} />
                          {index === 0 ? (
                            <Button
                              onClick={() => {
                                handleAddNewField(cmPricetype, data.marketPriceTypeId);
                              }}
                              style={{ height: "5px" }}
                              startIcon={<img alt="add" src="/assets/icons/button/add_circle.svg" />}
                            >
                              <p style={addButtonText}>Add New Field</p>
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                </>
              ))}
            </div>

            <div style={pmDetailsHeading}>
              <p style={pmDetailsHeadingText}>User Feedback prices</p>
            </div>
            <div style={pmUserFeedback}>
              <Grid container spacing={2}>
                {pmState.priceMatrixRecord.marketPrices &&
                  pmState.priceMatrixRecord.marketPrices["UserFeedback Prices"] &&
                  pmState.priceMatrixRecord.marketPrices["UserFeedback Prices"].map((data, index) => (
                    <Grid item xs={2}>
                      <TextField id={data.marketPriceId === null ? null : data.marketPriceId.toString()} label={data.name} name={data.marketPriceType.name} onChange={(e) => handleChange(e, data.name, data.marketPriceTypeId)} onBlur={(e) => handleUpdatePriceMatrix(e)} defaultValue={data.price} />
                      {index === 0 ? (
                        <Button
                          onClick={() => {
                            handleAddNewField("UserFeedback Prices", data.marketPriceTypeId);
                          }}
                          style={{ height: "5px" }}
                          startIcon={<img alt="add" src="/assets/icons/button/add_circle.svg" />}
                        >
                          <p style={addButtonText}>Add New Field</p>
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  ))}
              </Grid>
            </div>
          </>
        )}
        {!isSeller && (
          <div style={actions2}>
            <Button
              variant="outlined"
              style={{ ...addVariantButton, marginRight: "1em" }}
              onClick={() => {
                navigate(`/dashboard/priceMatrix${searchQuery ? `?search=${searchQuery}` : ""}`);
              }}
            >
              Cancel
            </Button>
            <span style={{ flexGrow: 12 }} />
            <Button style={addProductButton} onClick={(e) => handleConfirm(e)}>
              Confirm
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default PriceDetails;
