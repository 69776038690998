import { useContext, useEffect, useState } from "react";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import { Box, Divider, Typography, MenuItem, Avatar, IconButton, Popover, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { requestPermissionNotification } from "src/utils/firebaseUtil";
import { GlobalContext } from "src/context/GlobalState";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  //Request notification permission
  const [isTokenFound, setTokenFound] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(null);
  const [allowNotifications, setAllowNotification] = useState(false);

  const navigate = useNavigate();

  const {
    dispatch,
    state: { notificationState }
  } = useContext(GlobalContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const res = JSON.parse(localStorage.getItem("notificationEnable"));
    dispatch({ type: "allowNotification", payload: res ?? false });
  }, []);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login", { replace: true });

    setOpen(null);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const OrangeSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: orange[600],
      "&:hover": {
        backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity)
      }
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: orange[600]
    },
    "&.Mui-disabled": {
      opacity: 1,
      "& .MuiSwitch-switchBase.Mui-checked": {
        color: orange[600]
      },
      "& .MuiSwitch-track": {
        backgroundColor: orange[600]
      }
    }
  }));

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}
      >
        <Avatar src={userData?.profilePicUrl} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75
            }
          }
        }}
      >
        <Box sx={{ my: 1, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userData?.firstName} {userData?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {userData?.email}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        <Box sx={{ my: 1.0, px: 2.5, alignItems: "center", display: "flex" }}>
          <Typography variant="body2" noWrap>
            {" "}
            Allow Notifications
          </Typography>
          <OrangeSwitch
            checked={notificationState.allowNotification}
            onClick={(e) => {
              dispatch({ type: "allowNotification", payload: !notificationState.allowNotification });
              if (!notificationState.allowNotification) requestPermissionNotification(setTokenFound, setNotificationPermission);
              isTokenFound && e.target.checked ? setAllowNotification(true) : setAllowNotification(false);
            }}
          />
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
