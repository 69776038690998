import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { LoadingButton } from "@mui/lab";
import { IconButton, InputAdornment, TextField } from "@mui/material";
// components
import { resetPassword } from "src/api/auth";
import { GlobalContext } from "src/context/GlobalState";
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigate = useNavigate();

  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const {
    dispatch,
    state: { globalState }
  } = useContext(GlobalContext);

  const handleClick = async () => {
    if (passwordConfirm !== globalState.password) {
      alert("Passwords do not match");
      return;
    }

    try {
      const body = {
        password: globalState.password
      };

      const data = await resetPassword(body);

      if (data.httpStatusCode === 200) {
        navigate("/dashboard/products", { replace: true });
      } else {
        alert(data.message);
      }
    } catch (error) {}
    // navigate('/dashboard', { replace: true });
  };

  return (
    <>
      <TextField
        name="password"
        fullWidth
        label="Password"
        value={globalState.password}
        onChange={(e) => {
          dispatch({ type: "password", payload: e.target.value });
        }}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        name="passwordConfirm"
        fullWidth
        label="Confirm Password"
        value={passwordConfirm}
        onChange={(e) => {
          setPasswordConfirm(e.target.value);
        }}
        type={showPasswordConfirm ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} edge="end">
                <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <LoadingButton style={{ backgroundColor: "#FE7C19" }} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Reset Password
      </LoadingButton>
    </>
  );
}
