import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
// eslint-disable-next-line
import { useNavigate } from "react-router-dom";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";

const BrandField = ({ routePath }) => {
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  const [selectedBrand, setSelectedBrand] = useState(productState.brandDropdown.find((brand) => brand.brandId === productState?.productRecord.brandId) || null);

  useEffect(() => {
    setSelectedBrand(productState.brandDropdown.find((brand) => brand.brandId === productState?.productRecord.brandId) || null);
  }, [productState]);

  const handleChange = (e, val) => {
    if (val?.brandId === "POST") {
      dispatch({ type: "navigateBack", payload: true });
      navigate("/dashboard/brands/details/add", { state: { routePath } });
    } else {
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, brandId: val?.brandId, brandName: productState.brandDropdown.find((val) => val.brandId === productState?.brand.brandId)?.label ?? null } });
    }
  };

  const defaultBrandId = productState.brandDropdown.find((brand) => brand.brandId === productState?.productRecord.brandId)?.brandId;
  const defaultBrandName = productState.brandDropdown.find((brand) => brand.brandId === productState?.productRecord.brandId)?.label;
  return (
    <>
      <FormControl fullWidth margin="dense" sx={{ mb: 1 }}>
        <Autocomplete disabled={isSeller} disablePortal defaultValue={productState.brandDropdown.find((brand) => brand.brandId === productState?.productRecord.brandId)} value={selectedBrand} id="category-label" name="brand" options={productState.brandDropdown} getOptionLabel={(option) => option.label} onChange={(e, val) => handleChange(e, val)} sx={{ width: "100%" }} renderInput={(params) => <TextField {...params} label="Brand" variant="standard" />} />
      </FormControl>
    </>
  );
};

export default BrandField;
