import TableCell from "@mui/material/TableCell";
import { useContext, useEffect, useState } from "react";
import { CSVDownload } from "react-csv";
import { Helmet } from "react-helmet-async";
// @mui
import { Edit, Share } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Divider, Grid, InputAdornment, Snackbar, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
// components

import { addProductButton, addVariantButton, brandIcons, pTable } from "../../styles/styles";

// eslint-disable-next-line
import { Search } from "@mui/icons-material";
import { debounce } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createOrUpdateBrand, fetchBrandTableData, generateAdminBrand } from "src/api/products";
import Loader from "src/components/dialogs/Loader";
import ShareDialog from "src/components/dialogs/ShareDialog/ShareDialog";
import { colors } from "src/constants/constants";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";
import headerStyle from "src/styles/headerStyle";
import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import { getComparator, stableSort } from "src/utils/sortTables";
import { useQuery } from "src/utils/utils";
import { StyledTableCell2 } from "../../styles/styles";
import BrandDetails from "./BrandDetails";
import { statusCodes } from "src/utils/statusCodeUtils";
// mock
// import PRODUCTS from '../_mock/products';

// ----------------------------------------------------------------------

export default function BrandPage() {
  //Handle search persist
  const searchQuery = useQuery().get("search") || null;

  const [exportt, setExport] = useState(false);
  const [tableArray, setTableArray] = useState([]);
  const [exportTable, setExportTable] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("category");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchQuery);
  const [loading, setLoading] = useState(false);

  const [showShareDialog, setShowShareDialog] = useState(false);
  const [shareBrandLinkUrl, setShareBrandLinkUrl] = useState(null);
  const [copied, setCopied] = useState(false);

  const {
    dispatch,
    state: { brandState, alertState }
  } = useContext(GlobalContext);

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));
  useEffect(() => {
    if (searchText && searchText.trim().length >= 3 && searchText !== "null") {
      setSearchParams({ search: searchText });
    } else {
      setSearchText(null);
      setSearchParams({});
    }
  }, [searchText, setSearchParams]);

  const showBrandStatusValue = (statusId) => {
    switch (statusId) {
      case 0:
        return "None";
      case 1:
        return "Mobile";
      case 2:
        return "Web";
      case 3:
        return "Both";

      default:
        return "None";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchText && searchText.trim().length > 0 && searchText.trim().length < 3) return;
        updateTableData(pageSize, page, searchText);
      } catch (error) {
        setLoading(false);
        alert(error.message);
      }
    };

    if (!brandState.addBrand && !brandState.updateBrand) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [brandState.addBrand, brandState.updateBrand, searchText]);

  const navigate = useNavigate();

  const brandHeadCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Brand"
    },
    {
      id: "products",
      numeric: true,
      disablePadding: false,
      label: "No. of Products"
    },
    {
      id: "galleryImages",
      numeric: true,
      disablePadding: false,
      label: "Gallery Images"
    },
    {
      id: "categories",
      numeric: true,
      disablePadding: false,
      label: "No. of Categories"
    },
    {
      id: "active",
      numeric: false,
      disablePadding: false,
      label: "Show Brand Page"
    }
  ];

  const updateTableData = async (pageSize, page, searchText) => {
    setLoading(true);
    let textToSearch = searchText && searchText.trim().length >= 3 ? searchText : null;
    const response = await fetchBrandTableData(pageSize, page, textToSearch);

    if (response.httpStatusCode === 200) {
      const { brands, pageNumber, pageSize, totalBrandsCount } = response.result.data;
      brands.forEach((data, index) => [(brands[index].categoriesCount = data.tagBasedCategory.length)]);
      setTableArray(brands);
      setPage(parseInt(pageNumber));
      setPageSize(parseInt(pageSize));
      setTotalProductsCount(totalBrandsCount);
      setSearchParams({ ...searchParams, search: searchText });
      setLoading(false);
    } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
      setLoading(false);
      setTableArray([]);
      dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
      dispatch({ type: "showMessage", payload: true });
    } else {
      setTableArray([]);
      setLoading(false);
      alert(response.message);
    }
  };

  const fetchExportData = async (pageSize) => {
    const responseExport = await fetchBrandTableData(1000, 0, searchText);
    if (responseExport.httpStatusCode === 200) {
      const { brands } = responseExport.result.data;
      const array2 = [];
      brands.forEach((data) => {
        const { brandId, name, showBrand, noOfItems, tagBasedCategory, isArchived } = data;
        const body = {
          brandId,
          name,
          status: isArchived ? "InActive" : "Active",
          showBrand: showBrand ? "True" : "False",
          noOfProducts: noOfItems,
          noOfCategories: tagBasedCategory.length
        };
        console.log(body);
        array2.push(body);
      });

      console.log("array2", array2);

      setExportTable(array2);

      if (exportt) {
        setExport(false);

        setTimeout(() => {
          setExport(true);
        }, 500);
      } else {
        setExport(true);
      }
    } else if (responseExport.httpStatusCode === statusCodes.unAuthorizedAccess) {
      dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: responseExport?.message, body: responseExport?.displayMessage, type: "warning" } });
      dispatch({ type: "showMessage", payload: true });
    } else {
      alert(responseExport.message);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSubmit = async (selectedRecord) => {
    const body = {
      name: selectedRecord.name,
      imageUrl: selectedRecord.imageUrl,
      brandId: selectedRecord.brandId,
      description: selectedRecord.description,
      tagLine: selectedRecord.tagLine,
      coverPhoto: selectedRecord.coverPhoto,
      brandPhotos: selectedRecord.brandPhotos,
      showBrand: selectedRecord.showBrand,
      sortOrder: selectedRecord.sortOrder
    };

    const response = await createOrUpdateBrand(body);

    if (response.httpStatusCode === 200) {
      alert(brandState.addBrand ? "Brand Created Successfully" : "Brand Updated Successfully");
      dispatch({ type: "clear" });
    } else {
      alert(response.message);
    }
  };

  const headers = [
    { label: "brandId", key: "brandId" },
    { label: "name", key: "name" },
    { label: "No. of products", key: "noOfProducts" },
    { label: "No. of categories", key: "noOfCategories" },
    { label: "Show brand", key: "showBrand" },
    { label: "Status", key: "status" }
  ];

  const csvReport = {
    data: exportTable,
    headers,
    filename: `Brands.csv`
  };

  //Handle brand link share admin
  const handleBrandLinkShare = async (brandId) => {
    try {
      dispatch({ type: "loading", payload: true });
      const response = await generateAdminBrand({ brandId });
      if (response.httpStatusCode === 200) {
        setShareBrandLinkUrl(response?.result?.data?.deepLink);
        dispatch({ type: "loading", payload: false });
        setShowShareDialog(true);
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message);
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message ?? "An error occured during brandLink share");
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Brands </title>
      </Helmet>
      {/* <AddProductDialog />
      <AddVariantDialog /> */}
      {Loader()}
      {showShareDialog ? <ShareDialog openDialog={showShareDialog} setShowShareDialog={setShowShareDialog} shareLink={shareBrandLinkUrl} copied={copied} setCopied={setCopied} /> : null}
      <Snackbar open={copied} autoHideDuration={5000} message="Copied to clipboard" />
      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        {/* Header Container */}
        <Grid container spacing={1} sx={{ mt: -4 }} style={headerStyle.headerContainer}>
          <Grid item xs={4} sx={{ mb: brandState.addBrand || brandState.updateBrand ? 3.5 : 1 }}>
            <Typography variant="h4">Brand</Typography>
          </Grid>
          {brandState.addBrand || brandState.updateBrand ? null : (
            <Grid item xs={8} style={headerStyle.searchContainer}>
              <TextField
                onChange={debounce((e) => {
                  const searchValue = e.target.value;
                  if (!searchValue.trim()) {
                    setSearchText(null);
                    updateTableData(pageSize, 0, null);
                  } else if (searchValue.length < 3) {
                    setSearchText(searchValue);
                  } else {
                    setSearchText(searchValue);
                    setSearchParams({ search: searchValue });
                    updateTableData(pageSize, 0, searchValue);
                  }
                }, 300)}
                placeholder="Search Brand"
                defaultValue={searchText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                      <Search />
                    </InputAdornment>
                  ),
                  style: headerStyle.searchTextField,
                  inputProps: {
                    style: headerStyle.placeHolderText
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
        <Divider />
        {/*Subheader Container */}
        {brandState.addBrand || brandState.updateBrand ? (
          <Grid sx={{ my: 1 }} maxWidth="xxl" spacing={2} gap={2} style={{ ...headerStyle.subHeaderArrowContainer, justifyContent: "space-between" }}>
            <Grid item xs={6} style={{ display: "flex" }}>
              <Button
                onClick={() => {
                  dispatch({ type: "clear" });
                }}
              >
                <img alt="add" src="/assets/icons/button/back.svg" />
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "flex" }}>
              <Button onClick={(e) => dispatch({ type: "clear" })} variant="outlined" style={addVariantButton}>
                Cancel
              </Button>
              <Button
                style={{ ...addProductButton, marginLeft: "1em" }}
                onClick={() => {
                  handleSubmit(brandState.selectedRecord);
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            {!isSeller && (
              <Grid sx={{ my: 2 }} maxWidth="xxl" spacing={2} gap={2} style={headerStyle.subHeaderContainer}>
                <Grid item xs={4}>
                  <Button
                    onClick={() => {
                      navigate("/dashboard/brands/details/add");
                    }}
                    style={addProductButton}
                    endIcon={<img alt="add" src="/assets/icons/button/add_icon.svg" />}
                  >
                    Add Brand
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button onClick={() => fetchExportData(totalProductsCount)} variant="outlined" style={addVariantButton} endIcon={<img alt="add" src="/assets/icons/button/arrow_down_black.svg" />}>
                    Export
                  </Button>
                  {exportt ? <CSVDownload {...csvReport} /> : <></>}
                </Grid>
              </Grid>
            )}
          </>
        )}

        <Grid maxWidth="xxl">
          {brandState.addBrand || brandState.updateBrand ? (
            <BrandDetails />
          ) : (
            <>
              <TableContainer>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                      <StyledTableCell2>{"___"}</StyledTableCell2>
                      <EnhancedTableHead headCells={brandHeadCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={tableArray.length} />
                      <StyledTableCell2>{""}</StyledTableCell2>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <CircularProgress sx={{ color: colors.primary }} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      stableSort(tableArray, getComparator(order, orderBy)).map((record, index) => (
                        <TableRow key={record.brandId}>
                          <TableCell>
                            {" "}
                            <img style={brandIcons} alt="add" src={record.imageUrl} />{" "}
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.name}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.noOfItems}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.numberOfPhotos}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.categoriesCount}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={{ ...pTable, fontWeight: "500" }}>{showBrandStatusValue(record.showBrand)}</p>
                          </TableCell>
                          <TableCell align="right">
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Edit fontSize="small" sx={{ cursor: "pointer" }} onClick={() => navigate(`/dashboard/brands/details/${record.brandId}${searchText ? `?search=${searchText}` : ""}`)} />
                              <Share fontSize="small" sx={{ cursor: "pointer" }} onClick={() => handleBrandLinkShare(record.brandId)} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ height: "20px" }}>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, 25]}
                  component="div"
                  count={totalProductsCount}
                  rowsPerPage={pageSize}
                  page={page}
                  onPageChange={(e, newVal) => {
                    updateTableData(pageSize, newVal, searchText);
                  }}
                  onRowsPerPageChange={(e) => {
                    updateTableData(e.target.value, page, searchText);
                  }}
                />
              </div>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
