import { Button, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
// eslint-disable-next-line
import { addProductButton, addVariantButton, categoryText, orderDetailsText, pVarText } from "src/styles/styles";
// eslint-disable-next-line
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createOrUpdateBrand, fetchBrandsById } from "src/api/products";
import SystemLoader from "src/components/dialogs/Loader/Loader";
import BrandCoverUpload from "src/pages/brand/uploads/brandCoverUpload";
import BrandWebCoverUpload from "src/pages/brand/uploads/brandWebCoverUpload";
import BrandImageUpload from "src/pages/brand/uploads/brandImageUpload";
import EmptyGalleryUploads from "src/components/uploads/emptyGalleryUploads/emptyGalleryUploads";
import GalleryUpload from "src/components/uploads/galleryUpload/galleryUpload";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";
import buttonStyle from "src/styles/buttonStyle";
import headerStyle from "src/styles/headerStyle";
import { statusCodes } from "src/utils/statusCodeUtils";
import { getBrandValueArray, isValidYoutubeLink } from "src/utils/utils";
import { brandCreateOrUpdateValidator } from "src/validators/product.validation";

// ----------------------------------------------------------------------

export default function BrandDetails() {
  const galleryInputArray = [];
  const {
    dispatch,
    state: { brandState, globalState, alertState }
  } = useContext(GlobalContext);

  let { brandId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("search") || null;

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  const [youtubeLink, setYoutubeLink] = useState(null);

  useEffect(() => {
    if (/^\d+$/.test(brandId)) {
      fetchBrandDetailsById();
    }
  }, [brandId]);

  const fetchBrandDetailsById = async () => {
    try {
      dispatch({ type: "loading", payload: true });
      const response = await fetchBrandsById(brandId);
      if (response.httpStatusCode === 200) {
        const { name, brandId, imageUrl, isDefault, thumbnailName, tagLine, brandPhotos, coverPhoto, description, tagBasedCategory, showBrand, webCoverPhoto } = response.result.data;
        dispatch({
          type: "selectedRecord",
          payload: {
            ...brandState?.selectedRecord,
            name,
            brandId,
            imageUrl,
            isDefault,
            thumbnailName,
            tagLine,
            brandPhotos: brandPhotos ?? [],
            coverPhoto,
            description,
            tagBasedCategory,
            showBrand: showBrand ?? 0,
            webCoverPhoto
          }
        });
        if (showBrand) {
          dispatch({ type: "showBrandLabels", payload: getBrandValueArray(showBrand) });
        }
        dispatch({ type: "loading", payload: false });
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message ?? "Unable to fetch user details");
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  const handleBrandSaveOrUpdate = async () => {
    try {
      const validateResp = brandCreateOrUpdateValidator({ brandObj: brandState.selectedRecord, showBrandLabels: brandState.showBrandLabels });
      if (validateResp?.error) alert(validateResp?.error);
      dispatch({ type: "loading", payload: true });
      const response = await createOrUpdateBrand(validateResp);
      dispatch({ type: "loading", payload: false });
      if (response.httpStatusCode === 200) {
        alert(response?.message);
        if (globalState.navigateBack && location?.state?.routePath) {
          dispatch({ type: "navigateback", payload: false });
          navigate(`/dashboard/products/details/${location?.state?.routePath}`, { state: { brandId: response?.result?.data?.brandId, brandName: response?.result?.data?.brandName } });
        } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
          dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
          dispatch({ type: "showMessage", payload: true });
        } else {
          dispatch({ type: "clear" });
          navigate(`/dashboard/brands${searchQuery ? `?search=${searchQuery}` : ""}`);
        }
      } else {
        alert(response?.message ?? "An error occured");
      }
    } catch (error) {
      dispatch({ type: "loading", payload: true });
      alert(error?.message);
    }
  };

  for (let i = 0; i < 6; i++) {
    galleryInputArray.push(
      <>
        <Grid xs={4} style={{ heigt: "560px" }} sx={{ py: 4 }}>
          <GalleryUpload index={i} />
        </Grid>
      </>
    );
  }

  const showBrandStatus = [
    { label: "Mobile", value: 1 },
    { label: "Web", value: 2 }
  ];
  const handleShowBrandStatus = (e) => {
    const val = parseInt(e.target.value);
    if (e.target.checked) {
      dispatch({ type: "showBrandLabels", payload: [...brandState.showBrandLabels, val] });
    } else {
      const index = brandState.showBrandLabels.indexOf(val);
      if (index !== -1) {
        const updatedLabels = [...brandState.showBrandLabels];
        updatedLabels.splice(index, 1);
        dispatch({
          type: "showBrandLabels",
          payload: updatedLabels
        });
      }
    }
  };

  //Handle upload of youtube link
  const uploadYoutubeLinkBrandPhotos = (link) => {
    if (!link) {
      alert("Please enter a valid youtube url !");
      return;
    }
    if (!isValidYoutubeLink(link)) {
      alert("Please enter a valid youtube url !");
      return;
    }
    let updatedBrandPhotos = [...brandState.selectedRecord.brandPhotos];
    updatedBrandPhotos.push(youtubeLink);
    dispatch({
      type: "selectedRecord",
      payload: {
        ...brandState.selectedRecord,
        brandPhotos: updatedBrandPhotos
      }
    });
    setYoutubeLink(null);
    alert("Upload successful");
    return;
  };
  return (
    <>
      <SystemLoader />
      <Grid container spacing={1} sx={{ mt: 0, mx: 2 }} style={headerStyle.headerContainer}>
        <Grid item xs={4}>
          <Typography variant="h4">Brand</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{ mr: 1 }}
            onClick={() => {
              dispatch({ type: "clear" });
              navigate(`/dashboard/brands${searchQuery ? `?search=${searchQuery}` : ""}`);
            }}
          >
            <img alt="add" src="/assets/icons/button/back.svg" />
          </Button>
        </Grid>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Button sx={{ mr: 1 }} onClick={() => handleBrandSaveOrUpdate()} variant="outlined" style={addProductButton}>
            {/^\d+$/.test(brandId) ? "Update" : "Save"}
          </Button>
          <Button
            sx={{ mr: 1 }}
            onClick={() => {
              if (globalState.navigateBack && location?.state?.routePath) {
                dispatch({ type: "navigateback", payload: false });
                navigate(`/dashboard/products/details/${location?.state?.routePath}`);
              } else {
                dispatch({ type: "clear" });
                navigate(`/dashboard/brands${searchQuery ? `?search=${searchQuery}` : ""}`);
              }
            }}
            variant="outlined"
            style={addVariantButton}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} gap={2} sx={{ mx: 3 }}>
        <Grid xs={3}>
          <BrandImageUpload />
          <Grid my={1}>
            <p style={orderDetailsText}>
              <b>Show brand</b>
            </p>
            <FormGroup>
              {showBrandStatus.map((data) => (
                <FormControlLabel
                  sx={{ height: "25px" }}
                  control={
                    <Checkbox
                      onChange={(e) => handleShowBrandStatus(e)}
                      value={data.value}
                      disabled={isSeller}
                      size="medium"
                      checked={brandState.showBrandLabels.indexOf(data.value) === -1 ? false : true}
                      sx={{
                        color: "#FE7C19",
                        "&.Mui-checked": {
                          color: "#FE7C19"
                        }
                      }}
                    />
                  }
                  label={data?.label ?? "Ayoyoe"}
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>
        <Grid xs={6}>
          <Grid container spacing={2} direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">Cover Photo (Mobile)</Typography>
              <BrandCoverUpload />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">Cover Photo (Web)</Typography>
              <BrandWebCoverUpload />
            </Grid>
          </Grid>
          <TextField
            onChange={(e) => {
              dispatch({ type: "selectedRecord", payload: { ...brandState.selectedRecord, name: e.target.value } });
            }}
            fullWidth
            label="Brand Name"
            variant="outlined"
            value={brandState.selectedRecord.name}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
          <TextField
            onChange={(e) => {
              dispatch({ type: "selectedRecord", payload: { ...brandState.selectedRecord, tagLine: e.target.value } });
            }}
            fullWidth
            label="Tag Line"
            variant="outlined"
            value={brandState.selectedRecord.tagLine}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
          <p style={categoryText}>Categories</p>
          <div
            style={{
              padding: "20px",
              width: "100%",
              border: "1px solid #F2F2F2",
              borderRadius: "8px",
              background: "#F2F2F2"
            }}
          >
            <Grid container spacing={1}>
              {brandState.selectedRecord.tagBasedCategory &&
                brandState.selectedRecord.tagBasedCategory.map((data) => {
                  return (
                    <Grid spacing={1}>
                      <Chip sx={{ mr: 1 }} label={data?.categoryName ?? ""} variant="outlined" />
                    </Grid>
                  );
                })}
            </Grid>
          </div>
          <TextField
            aria-label="Description"
            name="brandDescription1"
            id="description"
            style={{ width: "100%" }}
            multiline
            rows={4}
            onChange={(e) => {
              if (e.target.value.length < 501) {
                dispatch({ type: "selectedRecord", payload: { ...brandState.selectedRecord, description: e.target.value } });
              } else {
                alert("Description limited to 500 characters only");
                const value = e.target.value.substring(0, 500);
                dispatch({ type: "selectedRecord", payload: { ...brandState.selectedRecord, description: value } });
              }
            }}
            InputLabelProps={{ shrink: true }}
            label="About the Brand"
            margin="normal"
            value={brandState.selectedRecord.description}
          />
          <hr style={{ width: "100%" }} />
          <p style={pVarText}>Photo Gallery</p>
          {brandState?.selectedRecord?.brandPhotos.length < 6 ? (
            <Grid
              my={2}
              gap={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <TextField onChange={(e) => setYoutubeLink(e.target.value ? e.target.value.trim() : e.target.value)} fullWidth label="Upload link from youtube" variant="outlined" value={youtubeLink ?? ""} InputLabelProps={{ shrink: true }} sx={{ margin: 0, height: "56px" }} />
              <Button
                sx={{
                  ...buttonStyle.simpleButton,
                  backgroundColor: "#EF7B22",
                  color: "#FFF",
                  padding: "8px 16px",
                  height: "56px",
                  display: "flex",
                  alignItems: "center"
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = "#EF7B22";
                  e.currentTarget.style.color = "#FFF";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = "#EF7B22";
                  e.currentTarget.style.color = "#FFF";
                }}
                onClick={() => uploadYoutubeLinkBrandPhotos(youtubeLink)}
              >
                Upload
              </Button>
            </Grid>
          ) : null}

          <div
            style={{
              paddingLeft: "20px",
              paddingBottom: "20px",
              boxSizing: "border-box",
              background: "#F2F2F2",
              borderWidth: "1px 1px 1px 1px",
              minHeight: "300px",
              borderStyle: "solid",
              borderColor: "#BFBFBF",
              borderRadius: "8px 8px 8px 8px",
              textAlign: "center"
            }}
          >
            {brandState.selectedRecord.brandPhotos.length === 0 ? (
              <EmptyGalleryUploads />
            ) : (
              <Grid container spacing={2}>
                {galleryInputArray}
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
