export const fetchTableData = async (body) => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/orderService/admin/orders/fetch`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchExportTableData = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/orderService/admin/orders/export`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchOrderDetails = async (orderId) => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/orderService/admin/order/${orderId}/fetchViewDetails`, {
      method: "GET",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const searchUserData = async (searchText) => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/userService/users/search?${new URLSearchParams({
        searchText
      })}`,
      {
        method: "GET",
        headers: {
          authorization: token
        }
      }
    );
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchPamojaExportData = async (body) => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/orderService/admin/pamoja/export`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const downloadOrderData = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/orderService/admin/orders/export/download`, {
      method: "POST",
      headers: {
        authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const downloadJipangeOrderData = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/orderService/admin/jipange/export/download`, {
      method: "POST",
      headers: {
        authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const searchOrderDetails = async (pageSize, page, searchText) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/orderService/admin/search/orders/?${new URLSearchParams({
        searchText,
        pageSize,
        pageNumber: page
      })}`,
      {
        method: "GET",
        headers: {
          authorization: token
        }
      }
    );
    return response.json();
  } catch (error) {
    return error;
  }
};

export const adminUpdateOrderStatus = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/orderService/admin/order/status/update`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchStatusLogs = async (orderId) => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/orderService/admin/order/${orderId}/fetchStatusLogs`, {
      method: "GET",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};
