import { useNavigate } from "react-router-dom";
// import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
// // eslint-disable-next-line
// import { ProductContext } from "src/states/productState";

import { Button, Container, Divider, Grid, Typography } from "@mui/material";
import headerStyle from "src/styles/headerStyle";

import { useContext } from "react";
import SystemLoader from "src/components/dialogs/Loader/Loader";
import { GlobalContext } from "src/context/GlobalState";
import { addVariantButton } from "src/styles/styles";
import { AddToCart } from "./components/add_to_cart";
import DropOff from "./components/drop_off";
import Pickup from "./components/pickup";
import { useDeliveryRequest } from "./context/deliveryRequestContext";
import { addDeliveryRequest } from "./services/api";

const DeliverRequestPage = () => {
  const { state } = useDeliveryRequest();
  const {
    dispatch,
    state: { globalState }
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "loading", payload: true });
    try {
      await addDeliveryRequest(state);
      alert("Delivery request created successfully");
      navigate(`/dashboard/orders/`, { replace: true });
    } catch (error) {
      alert(error); // `error` now correctly contains the `displayMessage` or a default error message
    } finally {
      dispatch({ type: "loading", payload: false });
    }
  };
  return (
    <>
      <SystemLoader />
      <Grid container spacing={1} sx={{ mt: 0, mx: 2 }} style={headerStyle.headerContainer}>
        <Grid item xs={4}>
          <Typography variant="h4">Orders</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{ mr: 1 }}
            onClick={() => {
              navigate(`/dashboard/orders`, { replace: true });
            }}
          >
            <img alt="add" src="/assets/icons/button/back.svg" />
          </Button>
          <p>New Delivery Request</p>
        </Grid>{" "}
        <Grid item xs={6} style={{ display: "flex", alignItems: "center", paddingRight: "2em" }}>
          <Button
            onClick={() => {
              navigate(`/dashboard/orders`, { replace: true });
            }}
            variant="outlined"
            style={addVariantButton}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <Container maxWidth="xxl">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Pickup />
              <DropOff />
            </Grid>
            <Grid item xs={5}>
              <AddToCart />
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default DeliverRequestPage;
