import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
// import { CSVLink } from 'react-csv'
import TableCell from "@mui/material/TableCell";
// @mui
import { Button, CircularProgress, Container, Divider, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
// components

import { pTable } from "../../styles/styles";

// eslint-disable-next-line
import { Search } from "@mui/icons-material";
import { fetchTableData } from "src/api/products";
import { GlobalContext } from "src/context/GlobalState";
import headerStyle from "src/styles/headerStyle";
import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import { pmHeadCells } from "src/utils/product";
import { getComparator, stableSort } from "src/utils/sortTables";
import { StyledTableCell } from "../../styles/styles";
import PriceDetails from "./priceDetails";
// mock
// import PRODUCTS from '../_mock/products';
import { debounce } from "lodash";
import { Link, useSearchParams } from "react-router-dom";
import { colors, filterStatus } from "src/constants/constants";
import { statusCodes } from "src/utils/statusCodeUtils";
import { useQuery } from "src/utils/utils";

// ----------------------------------------------------------------------

export default function PriceMatrixPage() {
  //Handle search persist
  const searchQuery = useQuery().get("search") || null;

  const [tableArray, setTableArray] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("category");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState(searchQuery);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(filterStatus.all);

  const {
    dispatch,
    state: { pmState, alertState }
  } = useContext(GlobalContext);

  useEffect(() => {
    if (searchText && searchText.trim().length >= 3 && searchText !== "null") {
      setSearchParams({ search: searchText });
    } else {
      setSearchText(null);
      setSearchParams({});
    }
  }, [searchText, setSearchParams]);

  useEffect(() => {
    if (!pmState.pmDetails) {
      try {
        if (searchText && searchText.trim().length > 0 && searchText.trim().length < 3) return;
        updateTableData(pageSize, page, searchText);
      } catch (error) {
        alert(error.message);
      }
    }
    // eslint-disable-next-line
  }, [pmState.pmDetails, searchText, status]);

  const updateTableData = async (pageSize, page, searchText) => {
    setLoading(true);
    let textToSearch = searchText && searchText.trim().length >= 3 ? searchText : null;
    const response = await fetchTableData({ pageSize, pageNumber: page, searchText: textToSearch, filter: { status: pmState.filterStatuses } });
    if (response.httpStatusCode === 200) {
      const { products, pageNumber, pageSize, totalProductsCount } = response.result.data;
      const array = [];
      products.forEach((data) => {
        const { brand, categories, isArchived, product, productVariantId, skuType, unit, variantType } = data;

        const body = {
          productVariantId,
          categories,
          category: categories[0]?.categoryName ?? null,
          categoryId: categories[0]?.categoryId ?? null,
          productName: product.name,
          productDesc: product.description,
          productId: product.productId,
          brandName: brand.name,
          brandId: brand.brandId,
          unit: unit.name,
          unitId: unit.unitId,
          variant: variantType.name,
          variantTypeId: variantType.variantTypeId,
          sku: skuType.name,
          skuID: skuType.skuTypeId,
          isArchived
        };
        array.push(body);
      });

      setTableArray(array);
      setPage(parseInt(pageNumber));
      setPageSize(parseInt(pageSize));
      setTotalProductsCount(totalProductsCount);

      setSearchParams({ ...searchParams, search: searchText });

      setLoading(false);
    } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
      dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
      dispatch({ type: "showMessage", payload: true });
      setTableArray([]);
    } else {
      setTableArray([]);
      setLoading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleGetPriceMatrix = (obj) => {
    let prices = [];
    Object.keys(obj).forEach(function (key, index1) {
      // obj[key]
      if (key === "WholeSale" || key === "UserFeedback Prices") {
        console.log("KEY", key);
      } else {
        obj[key].forEach((data, index2) => {
          if (data.price !== 0) {
            prices.push(data.price);
          }
        });
      }
    });
    const sortedPrices = prices.sort(function (a, b) {
      return a - b;
    });

    dispatch({ type: "matrixPrice", payload: sortedPrices[0] });
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Price Matrix </title>
      </Helmet>
      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        {/* Header Container */}
        <Grid container spacing={1} sx={{ mt: -4 }} style={headerStyle.headerContainer}>
          <Grid item xs={4}>
            <Typography variant="h4" sx={{ py: 2 }}>
              Pricing Matrix
            </Typography>
          </Grid>
          {pmState.pmDetails ? null : (
            <Grid item gap={2} xs={8} style={headerStyle.searchContainer}>
              <Grid>
                <FormControl style={{ width: "200px" }}>
                  <InputLabel id="Status">Status</InputLabel>
                  <Select
                    labelId="Status"
                    name="status"
                    value={status}
                    label="Status"
                    onChange={(e) => {
                      if (parseInt(e.target.value) === filterStatus.active) {
                        setStatus(filterStatus.active);
                        dispatch({ type: "filterStatuses", payload: [filterStatus.active] });
                      } else if (parseInt(e.target.value) === filterStatus.inActive) {
                        setStatus(filterStatus.inActive);
                        dispatch({ type: "filterStatuses", payload: [filterStatus.inActive] });
                      } else {
                        setStatus(filterStatus.all);
                        dispatch({ type: "filterStatuses", payload: [filterStatus.active, filterStatus.inActive] });
                      }
                    }}
                  >
                    <MenuItem value={filterStatus.active}>Active</MenuItem>
                    <MenuItem value={filterStatus.inActive}>InActive</MenuItem>
                    <MenuItem value={filterStatus.all}>All</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <TextField
                onChange={debounce((e) => {
                  const searchValue = e.target.value;
                  if (searchValue.length < 3) {
                    setSearchText(null);
                    updateTableData(pageSize, 0, null);
                  } else {
                    setLoading(true);
                    setSearchText(searchValue);
                    setSearchParams({ search: searchValue });
                    updateTableData(pageSize, 0, searchValue);
                  }
                }, 100)}
                placeholder="Search Product"
                defaultValue={searchText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                      <Search />
                    </InputAdornment>
                  ),
                  style: headerStyle.searchTextField,
                  inputProps: {
                    style: headerStyle.placeHolderText
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
        <Divider />
        {/*Subheader Container */}
        <Grid sx={{ my: 1 }} maxWidth="xxl" spacing={2} gap={2} style={{ ...headerStyle.subHeaderArrowContainer, justifyContent: "space-between" }}>
          {pmState.pmDetails ? (
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  dispatch({ type: "clear" });
                }}
              >
                <img alt="add" src="/assets/icons/button/back.svg" />
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6}>
              {" "}
            </Grid>
          )}
        </Grid>

        <Grid maxWidth="xxl">
          {pmState.pmDetails ? (
            <PriceDetails />
          ) : (
            <>
              <TableContainer>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                      <EnhancedTableHead headCells={pmHeadCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={tableArray.length} />
                      <StyledTableCell>Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <CircularProgress sx={{ color: colors.primary }} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      stableSort(tableArray, getComparator(order, orderBy)).map((record) => (
                        <TableRow key={record.productVariantId}>
                          <TableCell align="left">
                            <p style={{ ...pTable, color: record.category ? "#333" : "gray" }}>{record?.category ?? "unassigned"}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.productName}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.brandName}</p>
                          </TableCell>
                          {/* eslint-disable-next-line */}
                          <TableCell align="left">
                            <p style={pTable}>
                              <Link to={`/dashboard/priceMatrix/details/${record.productVariantId}${searchText ? `?search=${searchText}` : ""}`} style={{ color: "#404040", textDecoration: "underline" }}>
                                {record.variant} - {record.unit}
                              </Link>
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.sku}</p>
                          </TableCell>
                          <TableCell align="left">{!record.isArchived ? <img alt="add" src="/assets/icons/status/active-status.svg" /> : <img alt="add" src="/assets/icons/status/inactive-status.svg" />}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ height: "20px" }}>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, 25]}
                  component="div"
                  count={totalProductsCount}
                  rowsPerPage={pageSize}
                  page={page}
                  onPageChange={(e, newVal) => {
                    updateTableData(pageSize, newVal, searchText);
                  }}
                  onRowsPerPageChange={(e) => {
                    updateTableData(e.target.value, page, searchText);
                  }}
                />
              </div>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
