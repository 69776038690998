import { Close, Done, Error, Warning } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

const AlertPopup = ({ alertDetail, dispatch }) => {
  const alertColor = {
    warning: "#FFCC00",
    success: "#4CAF50",
    error: "#F44336"
  }[alertDetail?.type ?? "warning"];

  const iconColor = {
    warning: "#FFCC00",
    success: "#4CAF50",
    error: "#F44336"
  }[alertDetail?.type ?? "warning"];

  const borderColor = {
    warning: "#FFCC00",
    success: "#4CAF50",
    error: "#F44336"
  }[alertDetail?.type ?? "warning"];

  return (
    <Box
      sx={{
        borderRadius: "8px",
        boxShadow: 3
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          px: 1,
          py: 1,
          mt: 2,
          borderRadius: "8px 8px 0 0",
          bgcolor: alertColor,
          color: "white",
          width: "30vw"
        }}
      >
        <Typography variant="subtitle1" component="strong">
          {alertDetail?.title ?? ""}
        </Typography>
        <IconButton
          onClick={() => {
            dispatch({ type: "alertClear" });
          }}
          sx={{ color: "white" }}
          size="small"
        >
          <Close />
        </IconButton>
      </Box>

      {/* Body */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          bgcolor: "white",
          px: 1,
          py: 2,
          borderRadius: "0 0 8px 8px",
          gap: 1
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            border: `2px solid ${borderColor}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0.5
          }}
        >
          <IconButton sx={{ color: iconColor }}>{alertDetail?.type === "warning" ? <Warning fontSize="34px" /> : alertDetail?.type === "success" ? <Done fontSize="34px" /> : alertDetail?.type === "error" ? <Error fontSize="34px" /> : <Close fontSize="28px" />}</IconButton>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: "bold", color: "#2c2c2c" }}>
            {alertDetail?.heading ?? ""}
          </Typography>
          <Typography variant="caption" sx={{ color: "#595959" }}>
            {alertDetail?.body ?? ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AlertPopup;
