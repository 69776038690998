import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { isUserSeller } from "src/constants/user_constants";
// eslint-disable-next-line
import { GlobalContext } from "src/context/GlobalState";

const VATTypeFields = () => {
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  return (
    <FormControl fullWidth margin="dense">
      <InputLabel sx={{ ml: -1.5, mt: 1 }} id="vat-label">
        VAT Percentage
      </InputLabel>
      <Select
        disabled={isSeller}
        labelId="vat-label"
        value={productState.productRecord?.vatId ?? ""}
        name="vatType"
        label="VATType"
        variant="standard"
        onChange={(e) => {
          dispatch({ type: "productRecord", payload: { ...productState?.productRecord, vatId: e.target.value } });
        }}
        InputLabelProps={{ shrink: true }}
      >
        {productState.vatDropdown.map((data) => (
          <MenuItem value={data.vatId}>{data.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default VATTypeFields;
